import { createRoot } from "react-dom/client";
import "./index.scss";
import "./App.css";

import App from "./App";
import React from "react";
import { initIframe } from "./iframe";

if (process.env.NODE_ENV === "storybook") {
  const { worker } = require("./mocks/browser");
  worker.start();
}



function inIframe () {
  try {
      return window.self !== window.top;
  } catch (e) {
      return true;
  }
}

if(inIframe()) {
  initIframe()
}

else {
  createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  ); 
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
