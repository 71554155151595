import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { layoutSlice } from "../../layout/store/layout-slice";
import { AppContext } from "../../app/store";
import { push } from "redux-first-history";
import { Button, Empty } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useGetAllStandardReportsQuery } from "../reports/store/report-api-slice";

const AdminStandardReports = () => {
    const dispatch = useDispatch();
    const [appContext] = useContext(AppContext)
    const { data: allReports } = useGetAllStandardReportsQuery(
        {
            page: 1,
            per_page: 100,
            folderName: localStorage.getItem('__STANDARD_REPORTS_FOLDER_NAME__') || 'Standard Reports'
        }
    );

    const showTenant = (e : any) => {
        e.preventDefault();
        dispatch(push(`${appContext.basepath}/tenants/all`))
    };

    return (
        <div className="analytics-wrapper">
            <div className="detail-page-wrapper">

                <div className="description-panel p-32 position-relative">
                    <div className="card bg-light mt-24 mt-m-0 p-32" >



                        <div className="">
                            <h3 className="font-weight-semibold mb-24">Admin Standard Reports</h3>
                            {/* Button for Admin Standard Reports flow */}
                            {/* <Button onClick={(e) => showTenant(e)} type="primary" shape="default" style={{alignItems: 'center', display: 'flex', justifyContent: 'center'}}>Tenants</Button> */}
                            <div className="mt-32">
                                <ul className="standard-report-list">
                                    {
                                        allReports?.items?.map(report => (
                                            <li className="link-item" key={report.id} onClick={() => dispatch(push(`${appContext.basepath}/standard-reports-view/${report.id}`))}>
                                                <p className="font-weight-medium report-list-title">{report.name}</p>
                                                <p className="small text-truncate">{report.description}</p>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
};

export default AdminStandardReports;