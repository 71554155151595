import { useState, forwardRef, useImperativeHandle, Ref } from "react";
import { Modal } from "antd";
import {
  useCreateFolderMutation,
  useGetFolderQuery,
  useUpdateFolderMutation,
} from "./store/folder-api-slice";
import { FolderCreateUpdate } from "../../types/folder";
import FoldersForm from "./components/folders-form";

export enum FolderCRUDType {
  Create = 1,
  Edit,
}

export interface IFoldersModal {
  toggleModal: () => void;
  changeType: (type: FolderCRUDType) => void;
}

type props = {
  folderId?: number;
};

const FoldersModal = forwardRef(
  ({ folderId }: props, ref: Ref<IFoldersModal>) => {
    useImperativeHandle(ref, () => ({
      toggleModal: toggleModal,
      changeType: changeType,
    }));

    const [on, setOn] = useState(false);
    const toggleModal = () => setOn(!on);

    const [type, setType] = useState(FolderCRUDType.Create);
    const changeType = (type: FolderCRUDType) => setType(type);

    const { data: folder } = useGetFolderQuery(+(folderId || "0"), {
      skip: !folderId,
    });

    const [createFolder] = useCreateFolderMutation();
    const [updateFolder] = useUpdateFolderMutation();

    const handleOk = (values: FolderCreateUpdate) => {
      if (type === FolderCRUDType.Create) createFolder(values);
      else updateFolder(values);

      setOn(false);
    };

    const handleCancel = () => {
      setOn(false);
    };

    return (
      <Modal
        title={type === FolderCRUDType.Create ? "Create Collection" : "Edit Collection"}
        open={on}
        onCancel={handleCancel}
        footer={[]}
      >
        <FoldersForm
          onSubmitFolder={handleOk}
          onCancel={handleCancel}
          initialValues={type === FolderCRUDType.Create ? {} : folder}
        />
      </Modal>
    );
  }
);

export default FoldersModal;
