import { AnalyticsType, Load, SetAccessToken } from "./analytics";
import { store } from "./app/store";
import { push } from "redux-first-history";


type MessageEventType = {
    type: keyof typeof eventHandlerMap,
    args: unknown[]
}

type InitArgs = {
    type: AnalyticsType.Report
    token: string
    program: string
    api_url: string
    events: Array<string>
}

type UpdateTokenArgs = {
    token: string
}
type RedirectArgs = {
    path: string
}

const eventHandlerMap = {
    init: (event: MessageEvent<MessageEventType>, props: InitArgs) => {
        const element = document.getElementById('root')!;
        Load({
            element,
            type: props.type,
            accessToken: props.token,
            program: props.program,
            apiUrl: props.api_url,
            pbiEvents: props.events,
            eventHandler: (event: string, type: string) => {
                console.log(`${event}-${type}`)
                window.parent.postMessage({type, args:[event]}, '*')
            },
            onInit: () => {},
            basepath: ''
        })
    },

    updateToken: (event: MessageEvent<MessageEventType>, props: UpdateTokenArgs) => {
        SetAccessToken(props.token)
    },

    redirect: (event: MessageEvent<MessageEventType>, props: RedirectArgs) => {
        console.log(props)
        store.dispatch(push(props.path))
    }
} as const;


export const initIframe = async () => {
    window.addEventListener('message', (event:  MessageEvent<MessageEventType>) => {
        if(!event.data.type)return;
        const handler = eventHandlerMap[event.data.type] as (...args: any[]) => unknown
        
        if(handler) handler(event, ...event.data.args);
        else {
            console.warn(`Invalid message type: [${event.data?.type}]`)
        }
    })

    window.parent.postMessage({type: 'loaded', args: []}, '*')
}
  