import { Button,Menu,Dropdown, Space, Tag, Tooltip ,Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { useContext, useEffect, useState } from "react";
import List, { TableParams } from "../../layout/components/list";
import { layoutSlice } from "../../layout/store/layout-slice";
import { Schedule, Cron, SchedulingPeriodEnum, ScheduleStatusEnum } from "../../types/schedule";
import { useGetAllSchedulesForReportsQuery, useDeleteScheduleMutation  } from "../schedules/store/schedule-api-slice";
import { buildDaysOfWeek } from "../../utils/scheduleUtils"
import { useParams } from "react-router-dom";
import { AppContext, store, useAppDispatch } from "../../app/store";
import { hasPermission } from "../auth/store/auth-slice";
import { PERMISSIONS } from "../auth/permissions/permission-maps";
import { push } from "redux-first-history";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { HeaderReportButtons } from "../reports/layout/reports-config";
import '../reports/reports-listing.css'
import moment from "moment";


const scheduleServiceMap: Record<string, string> = JSON.parse(localStorage.getItem('__REPORTING_MODULE_SCHEDULE_SERVICE_MAP__') || '{"url":"https://dev-services.simplifysandbox.net/scheduler"}')

export const ScheduleListing = () => {
  const { reportId } = useParams();
  const dispatch = useAppDispatch();
  const [appContext] = useContext(AppContext)

  useEffect(() => {
    dispatch(layoutSlice.actions.updateTitle("My Schedules"));
    dispatch(layoutSlice.actions.updateSubTitle(''));
    dispatch(layoutSlice.actions.updateShowSider(false));
    dispatch(layoutSlice.actions.updateShowSubHeader(false));
    dispatch(layoutSlice.actions.updateShowGoBackButton(false));


    if(reportId){
      dispatch(
        layoutSlice.actions.updateButtonsToShow([ 
          // HeaderReportButtons.NewSchedule,
          // HeaderReportButtons.ViewAllReports
          // HeaderReportButtons.MoreOptions
        ])
      );
    }else{
      dispatch(
        // layoutSlice.actions.updateButtonsToShow([HeaderReportButtons.ViewAllReports])
        layoutSlice.actions.updateButtonsToShow([ 
            // HeaderReportButtons.NewSchedule,
            // HeaderReportButtons.ViewAllReports
            // HeaderReportButtons.MoreOptions
          ])
      );
    }
   
  }, [dispatch]);
  
  useEffect(() => {
    dispatch(layoutSlice.actions.updateSetLayoutClass("listing-page"));
    return function () {
      dispatch(layoutSlice.actions.updateSetLayoutClass(""))
    }
  }, []);
  

  // const [deleteSchedule] = useDeleteScheduleMutation();

  const deleteSchedule = async (task_id: number) => {
      const url = scheduleServiceMap.deleteSchedule || scheduleServiceMap.url
      const response = await fetch(`${url}/api/v2/delete_schedule`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          task_id
        })
      })
  }

  const columns: ColumnsType<Cron> = [
    {
      title: "Schedule Name",
      dataIndex: "task_name",
      key: "task_name",
      width: 150,
      sorter: true,
      fixed: "left",
      ellipsis: {
        showTitle: false,
      },
      render: (task_name, {report_code, task_id}) => (
        <Tooltip className="td-name" placement="topLeft" title={task_name}>
          <Typography.Link onClick={_ => dispatch(push(`${appContext.basepath}/reports/${report_code}/schedules/${task_id}/edit`))} >{task_name}</Typography.Link>
        </Tooltip>
      ),
    },
    // {
    //   title: "Status",
    //   dataIndex: "active",
    //   key: "active",
    //   width: 75,
    //   sorter: true,
    //   ellipsis: {
    //     showTitle: false,
    //   },
    //   render: (active) => (
    //     <>
    //         <Tag color={active  ? 'green': 'volcano'} key={active  ? ScheduleStatusEnum.Active: ScheduleStatusEnum.Inactive}>
    //           {active ? ScheduleStatusEnum.Active: ScheduleStatusEnum.Inactive}
    //         </Tag>
    //     </>
    //   ),
    // },
      {
        title: "Report Name",
        dataIndex: "report_name",
        key: "report_name",
        width: 150,
        sorter: true,
        fixed: "left",
        ellipsis: {
          showTitle: false,
        },
        render: (name) => (
          <Tooltip className="td-name" placement="topLeft" title={name}>
            {name}
          </Tooltip>
        ),
      },
    {
      title: "Next run",
      dataIndex: "next_run",
      key: "next_run",
      width: 200,
      render: (next_run, {time_zone}) => (
        <Tooltip className="td-owner" placement="topLeft" title={next_run?.split(',')[1]?.slice(0,-7)}>
          {/* {next_run?.split(0, -3)} */}
          {/* {moment(next_run).format(moment.localeData().longDateFormat('LLL'))} */}
          {/* {moment(next_run).format('MMMM D, YYYY h A')} */}
          {/* {buildDaysOfWeek(days)} */}
          {next_run?.split(',')[1]?.slice(0,-7)}
        </Tooltip>
      ),
    },
    {
        title: "Timezone",
        dataIndex: "time_zone",
        key: "time_zone",
        width: 150,
        sorter: true,
        fixed: "left",
        ellipsis: {
          showTitle: false,
        },
        render: (time_zone) => (
          <Tooltip className="td-name" placement="topLeft" title={time_zone}>
            {time_zone}
          </Tooltip>
        ),
      },
    {
      title: "Created By",
      dataIndex: "created_by_name",
      key: "created_by_name",
      width: 150,
      render: (createdBy) => (
        <Tooltip className="td-owner" placement="topLeft" title={createdBy}>
          {createdBy}
        </Tooltip>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: 100,
      render: (_, schedule) => (
        <Space size="middle">
          {hasPermission(store.getState(), PERMISSIONS.scheduleReport) ? (
            <>
              <Tooltip title="View">
                <Button
                  icon={<EyeOutlined />}
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(push(`${appContext.basepath}/reports/${schedule.report_code}/schedules/${schedule.task_id}/edit`));
                    return false;
                  }}
                />
              </Tooltip>
              {/* <Tooltip title="Delete">
              <Button
                icon={<DeleteOutlined />}
                onClick={async () => await deleteSchedule(schedule.id)}
              />
              </Tooltip> */}
              <Dropdown.Button
              type="default"
              menu={{
                items: [
                  hasPermission(store.getState(), PERMISSIONS.scheduleReport)
                  ?{
                    key: 1,
                    label: "Delete Schedule",
                    icon: <DeleteOutlined />,
                    onClick: async () =>
                    await deleteSchedule(schedule.task_id),
                  }:null,
                  hasPermission(store.getState(), PERMISSIONS.scheduleReport)
                  ?{
                    key:2,
                    label: "Edit Details",
                    icon: <EditOutlined />,
                    onClick:()=>
                    dispatch(push(`${appContext.basepath}/reports/${schedule.report_code}/schedules/${schedule.task_id}/edit`))       
                  }:null,
                ],
              }}> 
              </Dropdown.Button>
             
            </>
          ) : (
            ""
          )}

        </Space>
      ),
    }
  ];

  /** Pagination */
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  // const { data: schedules } = useGetAllSchedulesForReportsQuery({
  //   page: tableParams.pagination?.current || 1,
  //   per_page: tableParams.pagination?.pageSize || 10,
  //   sortBy: tableParams.sortField,
  //   sortOrder: tableParams.sortOrder,
  //   reportId: reportId,
  // });
  const [schedules, setSchedules] = useState<ListResponse<Cron>>()

  useEffect(() => {

    const abortController = new AbortController()
    const loadCrons = async () => {
      const url = scheduleServiceMap.getUsers || scheduleServiceMap.url
      const response = await fetch(`${url}/api/v2/schedular`)
      const crons: Array<Cron> = await response.json()

      setSchedules({
        hasNextPage: false,
        hasPreviousPage: false,
        pageNumber: 1,
        pageSize: Math.max(30, crons.length),
        totalCount: crons.length,
        totalPages: 1,
        items: crons
      })
    }

    loadCrons()

    return () => {
      abortController.abort()
    }

  }, [])
  

  return (
      <div>
        
      <List
        columns={columns}
        data={schedules!}
        setTableParams={setTableParams}
      />
    </div>
    
  );
};

export default ScheduleListing;