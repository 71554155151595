import { useEffect } from "react";
import { goBack } from "redux-first-history";
import { useAppDispatch, useTypedSelector } from "../../app/store";
import { AccessDeniedStyles } from "./AccessDenied-styles";

const AccessDenied = () => {
  const dispatch = useAppDispatch();
  const { permissions, onAccessDeniedPermission } = useTypedSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (permissions.some((p) => p === onAccessDeniedPermission)) {
      dispatch(goBack());
    }
  }, [dispatch, permissions, onAccessDeniedPermission]);

  return (
    <AccessDeniedStyles>
      <div className="text-wrapper">
        <div className="title" data-content="404">
          403 - ACCESS DENIED
        </div>

        <div className="subtitle">
          Oops, You don't have permission to access this page.
        </div>
      </div>
    </AccessDeniedStyles>
  );
};

export default AccessDenied;
