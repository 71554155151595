import { useState, forwardRef, useImperativeHandle, Ref, useContext } from "react";
import { Modal } from "antd";
import { models } from "powerbi-client";
import { ExportTypes } from "./export-to";
import CloneForm from "./clone-form";
import { useCloneReportMutation, useCloneStandardReportMutation } from "../../features/reports/store/report-api-slice";
import { useCloneDashboardMutation } from "../../features/dashboards/store/dashboard-api-slice";
import { useDispatch } from "react-redux";
import { push } from "redux-first-history";
import { AppContext } from "../../app/store";


export enum CloneType {
  Report = 1,
  StandardReport,
  Dashboard,
}

export interface ICloneModal {
  toggleModal: () => void;
  changeId: (id: number) => void;
  changeType: (type: CloneType) => void;
}

const CloneModal = forwardRef((_undefined, ref: Ref<ICloneModal>) => {
  useImperativeHandle(ref, () => ({
    toggleModal: toggleModal,
    changeType: changeType,
    changeId: changeId,
  }));

  const [on, setOn] = useState(false);
  const toggleModal = () => setOn(!on);

  const [id, setId] = useState<number>(0);
  const changeId = (id: number) => setId(id);

  const [type, setType] = useState(CloneType.Report);
  const changeType = (type: CloneType) => setType(type);


  const [cloneReport] = useCloneReportMutation(); 
  const [cloneStandardReport] = useCloneStandardReportMutation();
  const [cloneDashboard] = useCloneDashboardMutation();
  
  const dispatch = useDispatch();
  const [appContext] = useContext(AppContext)


  const handleOk = async (values: any) => {
    if (type === CloneType.Report) {
      const response = await cloneReport({ id: id, name: values.name}).unwrap();
      console.log(response)
      dispatch(push(`${appContext.basepath}/reports/${response}`))

    }
    else if (type === CloneType.StandardReport) {
      const response = await cloneStandardReport({ id: id, name: values.name,folderId: values.folderId}).unwrap();
      console.log(response)
      dispatch(push(`${appContext.basepath}/reports/${response}`))
    }
    else if (type === CloneType.Dashboard) {
      const response = await cloneDashboard({id: id, name: values.name}).unwrap();
      console.log(response)
      dispatch(push(`${appContext.basepath}/dashboards/${response}`))
    }

    setOn(false);
  };

  const handleCancel = () => {
    setOn(false);
  };

  return (
    <Modal title="Clone" open={on} onCancel={handleCancel} footer={[]}>
      <CloneForm
        onClone={handleOk}
        onCancel={handleCancel}
        initialValues={{}}
      />
    </Modal>
  );
});

export default CloneModal;
