import { api } from "../../../app/baseQueryWithReauth";
import { User } from "../../../types/user";

export const userApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    searchUsers: builder.query<ListResponse<User>, string>({
      query: (searchTerm) => `/user/searchUsers?searchTerm=${searchTerm}`,
    }),
    searchUsersShareReport: builder.query<ListResponse<User>, string>({
      query: (searchTerm) => `/user/searchForSchedule?searchTerm=${searchTerm}`,
    })
  }),
});

export const { 
  useLazySearchUsersQuery, 
  useLazySearchUsersShareReportQuery 
} = userApiSlice;