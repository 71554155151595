import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { allTagTypes, api } from "../../../app/baseQueryWithReauth";
import { RootState } from "../../../app/store";
import { PERMISSIONS } from "../permissions/permission-maps";
import { authApiSlice } from "./auth-api-slice";

export interface AuthState {
  isInitialized: boolean;
  isLoggedIn: boolean;
  accessToken?: string;
  redirectPath?: string;
  tenantId?: string;
  apiUrl?: string;
  permissions: string[];
  onAccessDeniedPermission?: string;
  onAccessDeniedPath?: string;
}

const initialState: AuthState = {
  isInitialized: false,
  isLoggedIn: false,
  permissions: [],
};

export const authlogin = createAsyncThunk(
  "auth/login",
  async (
    payload: { token: string; tenantId: string; apiUrl: string },
    thunkAPI
  ) => {
    thunkAPI.dispatch(api.util.invalidateTags(allTagTypes));

    return payload;
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateRedirectPath(state, action) {
      state.redirectPath = action.payload.redirectPath;
    },
    updateApiUrl(state, action) {
      state.apiUrl = action.payload.apiUrl;
    },
    updateTenant(state, action) {
      state.tenantId = action.payload.tenantId;
    },
    refreshToken(state, action) {
      state.isLoggedIn = true;
      state.accessToken = action.payload.token;
    },
    onAccessDenied(state, action) {
      state.onAccessDeniedPermission = action.payload.onAccessDeniedPermission;
      state.onAccessDeniedPath = action.payload.onAccessDeniedPath;
    },
    logout(state) {
      state.isLoggedIn = false;
      state.accessToken = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authlogin.pending, (state, action) => {
      state.isLoggedIn = true;
      state.accessToken = action.meta.arg.token;
      state.tenantId = action.meta.arg.tenantId;
      state.apiUrl = action.meta.arg.apiUrl;
    });
    builder.addMatcher(
      authApiSlice.endpoints.getRoles.matchFulfilled,
      (state, action) => {
        state.isInitialized = true;
        state.permissions = action.payload;
      }
    );
  },
});

export const {
  logout,
  refreshToken,
  updateRedirectPath,
  updateTenant,
  onAccessDenied,
} = authSlice.actions;

export const hasPermission = createDraftSafeSelector(
  [
    (state: RootState) => state.auth.permissions,
    (_, permissions: PERMISSIONS | PERMISSIONS[]) => {
      return permissions;
    },
  ],
  (userPermissions, permissions) => {
    return Array.isArray(permissions)
      ? userPermissions.some((i) => permissions.some((p) => p === i))
      : userPermissions.some((i) => i === permissions.toString());
  }
);

export const authReducer = persistReducer(
  {
    key: "rtk:auth",
    storage,
    whitelist: [
      "isLoggedIn",
      "accessToken",
      "isAuthenticated",
      "redirectPath",
      "tenantId",
      "apiUrl"
    ],
  },
  authSlice.reducer
);
