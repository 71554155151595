import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { layoutSlice } from "../../layout/store/layout-slice";
import { AppContext } from "../../app/store";
import { push } from "redux-first-history";
import { useGetAllStandardReportsQuery, useGetAllReportsQuery } from "../reports/store/report-api-slice";

const StandardReports = () => {
    const dispatch = useDispatch();
    const [appContext] = useContext(AppContext)

    const isStandardReport = false;

    const getReportUrl = (reportId: number, options: {isStandardReport?:boolean}={}) => {
        if(options.isStandardReport===undefined) options.isStandardReport = isStandardReport
        if(options.isStandardReport) return `${appContext.basepath}/standard-reports-view/${reportId}`
        return `${appContext.basepath}/reports/${reportId}`
    }
    
    const { data: allStandardReports } = useGetAllStandardReportsQuery(
        {
          page: 1,
          per_page: 100,
          folderName: localStorage.getItem('__STANDARD_REPORTS_FOLDER_NAME__') || 'Standard Reports'
        }, {
            skip: !isStandardReport
        });
    const { data: allReports } = useGetAllReportsQuery(
        {
        page: 1,
        per_page: 100,
        folderName: localStorage.getItem('__STANDARD_REPORTS_FOLDER_NAME__') || 'Standard Reports'
        }, {
            skip: isStandardReport
        });
      const [searchInput, setSearchInput] = useState("");

      const filteredReports = (isStandardReport? allStandardReports : allReports)?.items?.filter((report) =>
           report.name.toLowerCase().includes(searchInput.toLowerCase())
       );
      
    return (
        <div className="analytics-wrapper">
            <div className="detail-page-wrapper">

                <div className="description-panel p-32 position-relative">
                    <div className="card bg-light mt-24 mt-m-0 p-32" >



                        {/* <div className=""> */}
                        <div className="d-flex align-items-center justify-content-between mb-24">
                            <h3 className="font-weight-semibold mb-24">Standard Reports</h3>
                            <div className="d-flex">
                                <div className="position-relative search-head">
                                <input type="text" className="form-control" placeholder="Search" value={searchInput} onChange={(e) => setSearchInput(e.target.value)}></input>
                                <i className="material-icons search-icon">search</i>
                                </div>
                            </div>
                            </div>
                            <div className="mt-32">
                                {/* <h4 className="d-flex align-items-center mb-24">
                                    <span className="icon-style green mr-12">
                                        <i className="material-symbols-outlined">vital_signs</i>
                                    </span>
                                    <span className="font-weight-medium">Activity Reports</span>
                                </h4> */}
                                <ul className="standard-report-list">
                                    {
                                     filteredReports?.map(report=>(
                                        <li className="link-item" key={report.id} onClick={() => dispatch(push(getReportUrl(report.id)))}>                                       
                                
                                        <p className="font-weight-medium report-list-title">{report.name}</p>
                                        <p className="small text-truncate">{report.description}</p>
                                    
                                   </li>
                                    ))
                                    }
                                    </ul>                                                            
                                    {/* <li className="link-item" onClick={() => dispatch(push(`${appContext.basepath}/reports/7?viewAsTemplate=true`))}>
                                        <p className="font-weight-medium report-list-title">Job Activity Report</p>
                                        <p className="small">Tracks activities related to job openings, such as postings, interviews, and offers.</p>
                                    </li>
                                    <li className="link-item" onClick={() => dispatch(push(`${appContext.basepath}/reports/507?viewAsTemplate=true`))}>
                                        <p className="font-weight-medium report-list-title">Job Approval Workflow Report</p>
                                        <p className="small">Highlights the stages and approvals within job workflows.</p>
                                    </li>
                                    <li className="link-item" onClick={() => dispatch(push(`${appContext.basepath}/reports/507?viewAsTemplate=true`))}>
                                        <p className="font-weight-medium report-list-title">Job Approval Workflow Aging Report</p>
                                        <p className="small">Monitors the time spent at each stage of job approval workflows.</p>
                                    </li>
                                    <li className="link-item" onClick={() => dispatch(push(`${appContext.basepath}/reports/507?viewAsTemplate=true`))}>
                                        <p className="font-weight-medium report-list-title">Headcount Report</p>
                                        <p className="small">Gives an overview of employee numbers by various criteria.</p>
                                    </li>
                                    <li className="link-item" onClick={() => dispatch(push(`${appContext.basepath}/reports/507?viewAsTemplate=true`))}>
                                        <p className="font-weight-medium report-list-title">Headcount Trend Report</p>
                                        <p className="small">Analyzes headcount changes over time.</p>
                                    </li>
                                    <li className="link-item" onClick={() => dispatch(push(`${appContext.basepath}/reports/507?viewAsTemplate=true`))}>
                                        <p className="font-weight-medium report-list-title">Timesheet Report</p>
                                        <p className="small">Captures hours worked on projects or tasks.</p>
                                    </li>
                                    <li className="link-item" onClick={() => dispatch(push(`${appContext.basepath}/reports/507?viewAsTemplate=true`))}>
                                        <p className="font-weight-medium report-list-title">Timesheet Aging Report</p>
                                        <p className="small">Analyzes outstanding timesheets and their aging.</p>
                                    </li>
                                    <li className="link-item" onClick={() => dispatch(push(`${appContext.basepath}/reports/507?viewAsTemplate=true`))}>
                                        <p className="font-weight-medium report-list-title">Missing Timesheet Report</p>
                                        <p className="small">Identifies employees with missing timesheets.</p>
                                    </li>
                                    <li className="link-item" onClick={() => dispatch(push(`${appContext.basepath}/reports/507?viewAsTemplate=true`))}>
                                        <p className="font-weight-medium report-list-title">Pending Timesheet Report</p>
                                        <p className="small">Lists timesheets in the approval process.</p>
                                    </li>
                                    <li className="link-item" onClick={() => dispatch(push(`${appContext.basepath}/reports/507?viewAsTemplate=true`))}>
                                        <p className="font-weight-medium report-list-title">Spend Report</p>
                                        <p className="small">Tracks expenses by type, employee, and category.</p>
                                    </li>
                                    <li className="link-item" onClick={() => dispatch(push(`${appContext.basepath}/reports/507?viewAsTemplate=true`))}>
                                        <p className="font-weight-medium report-list-title">Spend by Candidate Report</p>
                                        <p className="small">Reports candidate-related expenses.</p>
                                    </li>
                                    <li className="link-item" onClick={() => dispatch(push(`${appContext.basepath}/reports/507?viewAsTemplate=true`))}>
                                        <p className="font-weight-medium report-list-title">User Report</p>
                                        <p className="small">Provides user-related data, including roles, activity, and status.</p>
                                    </li> */}

                                {/* </ul> */}
                                {/* <h4 className="d-flex align-items-center mb-24">
                                    <span className="icon-style pink mr-12">
                                        <i className="material-symbols-outlined">trending_up</i>
                                    </span>
                                    <span className="font-weight-medium">Performance Reports</span>
                                </h4>

                                <ul className="standard-report-list">
                                    <li>
                                        <p className="font-weight-medium report-list-title">Cycle Time Report</p>
                                        <p className="small">Measures the duration of processes or workflows.</p>
                                    </li>
                                    <li>
                                        <p className="font-weight-medium report-list-title">Tenure Report</p>
                                        <p className="small">Displays employee tenure in various roles and departments.</p>
                                    </li>
                                    <li>
                                        <p className="font-weight-medium report-list-title">Assignment End Report</p>
                                        <p className="small">Reports the end dates of employee assignments and reasons.</p>
                                    </li>

                                </ul>

                                <h4 className="d-flex align-items-center mb-24">
                                    <span className="icon-style green mr-12">
                                        <i className="material-symbols-outlined">payments</i>
                                    </span>
                                    <span className="font-weight-medium">Finance Reports</span>
                                </h4>

                                <ul className="standard-report-list">
                                    <li>
                                        <p className="font-weight-medium report-list-title">Budget Report</p>
                                        <p className="small">Compares budgeted expenses with actual spending.</p>
                                    </li>
                                    <li>
                                        <p className="font-weight-medium report-list-title">Budget Spend Report</p>
                                        <p className="small">Breaks down spending compared to budget by expense type.</p>
                                    </li>

                                    <li>
                                        <p className="font-weight-medium report-list-title">Budget Report High Level</p>
                                        <p className="small">Summarizes high-level budget categories and variances.</p>
                                    </li>

                                    <li>
                                        <p className="font-weight-medium report-list-title">Turnover Report</p>
                                        <p className="small">Examines employee departures and reasons for turnover.</p>
                                    </li>

                                    <li>
                                        <p className="font-weight-medium report-list-title">Invoice Report</p>
                                        <p className="small">Provides details on client invoices, payments, and due dates.</p>
                                    </li>

                                </ul>

                                <h4 className="d-flex align-items-center mb-24">
                                    <span className="icon-style pink mr-12">
                                        <i className="material-symbols-outlined">extension</i>
                                    </span>
                                    <span className="font-weight-medium">Custom Reports</span>
                                </h4>

                                <ul className="standard-report-list">
                                    <li>
                                        <p className="font-weight-medium report-list-title">Submission Report</p>
                                        <p className="small">Records candidate submissions for specific jobs.</p>
                                    </li>
                                    <li>
                                        <p className="font-weight-medium report-list-title">Offer Report</p>
                                        <p className="small">Details job offers, including statuses, salaries, and expiration dates.</p>
                                    </li>

                                </ul>
                                <h4 className="d-flex align-items-center mb-24">
                                    <span className="icon-style green mr-12">
                                        <i className="material-symbols-outlined">tag</i>
                                    </span>
                                    <span className="font-weight-medium">Trend Reports</span>
                                </h4>

                                <ul className="standard-report-list">
                                    <li>
                                        <p className="font-weight-medium report-list-title">Diversity Spend Report </p>
                                        <p className="small">Monitors expenses based on diversity factors.</p>
                                    </li>
                                    <li>
                                        <p className="font-weight-medium report-list-title">Rate Card Compliance Report</p>
                                        <p className="small">Ensures compliance with rate card rates.</p>
                                    </li>
                                    <li>
                                        <p className="font-weight-medium report-list-title">Tenure Trend Report</p>
                                        <p className="small">Examines changes in employee tenure.</p>
                                    </li>

                                    <li>
                                        <p className="font-weight-medium report-list-title">Turnover Trend Report</p>
                                        <p className="small">Tracks turnover rates and reasons over time.</p>
                                    </li>

                                    <li>
                                        <p className="font-weight-medium report-list-title">Spend Trend Report</p>
                                        <p className="small">Visualizes spending trends and variances.</p>
                                    </li>

                                </ul> */}
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        
    );
};

export default StandardReports;
