import { EnumDictionary, getItem } from "../../../layout/main-layout-sider";
import { Link } from "../../../types/layout";
import { FolderFilled, RiseOutlined } from "@ant-design/icons";
import { PERMISSIONS } from "../../auth/permissions/permission-maps";
import { hasPermission } from "../../auth/store/auth-slice";
import { store } from "../../../app/store";

export enum HeaderReportButtons {
  NewReport = 1,
  SaveNewReport,
  SaveAsDraftNewReport,
  CancelNewReport,
  NewSchedule,

  SaveReportSchedule,
  CancelReportSchedule,
  ViewAllSchedules,
  ViewAllReports,
  ViewAllDashboards,

  ExportOptions,
  // ExportJson,
  ExportExcel,
  ExportCSV,

  MoreOptions = 20,

  EditPBIReport = 40,
  EditPBIReportOption,
  ExitEditPBIReport,

  EditFolder = 60,

  UseReportTemplate,
  Clone,
  CloneOption,
  ScheduleOption,
  saveChanges
}

export enum ReportsListType {
  Recent = 1,
  CreatedByMe,
  Private,
  Public,
  SharedWithMe,
  All,
}
export enum TenantsListType {
  All = 1,
}

export enum FoldersListType {
  All = 10,
  CreatedByMe,
  SharedWithMe,
}

export const links: EnumDictionary<ReportsListType, Link> = {
  [ReportsListType.Recent]: {
    label: "Recent",
    link: "/reports/recent",
    permission: PERMISSIONS.listReports,
  },
  [ReportsListType.CreatedByMe]: {
    label: "Created By Me",
    link: "/reports/createdByMe",
    permission: PERMISSIONS.listReports,
  },
  [ReportsListType.Private]: {
    label: "Private Reports",
    link: "/reports/private",
    permission: PERMISSIONS.listReports,
  },
  [ReportsListType.Public]: {
    label: "Public Reports",
    link: "/reports/public",
    permission: PERMISSIONS.listReports,
  },
  [ReportsListType.SharedWithMe]: {
    label: "Shared Reports",
    link: "/reports/sharedWithMe",
    permission: PERMISSIONS.listReports,
  },
  [ReportsListType.All]: {
    label: "All Reports",
    link: "/reports/all",
    permission: PERMISSIONS.listReports,
  },
};

export const folderLinks: EnumDictionary<FoldersListType, Link> = {
  [FoldersListType.All]: {
    label: "All Collections",
    link: "/reports/folders",
    permission: PERMISSIONS.listFolders,
  },
  [FoldersListType.CreatedByMe]: {
    label: "Created By Me",
    link: "/reports/folders/createdByMe",
    permission: PERMISSIONS.listFolders,
  },
  [FoldersListType.SharedWithMe]: {
    label: "Shared with Me",
    link: "/reports/folders/sharedWithMe",
    permission: PERMISSIONS.listFolders,
  },
};

export function getMenuItems() {
  return [
    hasPermission(store.getState(), PERMISSIONS.listReports)
      ? getItem(
          <span>
            <RiseOutlined style={{ marginRight: "4px" }} />
            Reports
          </span>,
          "reports",
          null,
          Object.entries(links)
            .filter(([k, v]) => hasPermission(store.getState(), v.permission))
            .map(([k, v]) => getItem(v.label, k)),
          "group"
        )
      : null,
    hasPermission(store.getState(), PERMISSIONS.listFolders)
      ? getItem(
          <span>
            <FolderFilled style={{ marginRight: "4px" }} />
            Collections
          </span>,
          "folders",
          null,
          Object.entries(folderLinks)
            .filter(([k, v]) => hasPermission(store.getState(), v.permission))
            .map(([k, v]) => getItem(v.label, k)),
          "group"
        )
      : null,
  ];
}
