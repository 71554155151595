import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { push, replace, goBack } from "redux-first-history";
import { useGetDashboardTemplatesQuery } from "./store/dashboard-api-slice";
import { AppContext } from "../../app/store";
import { Image, Space } from "antd";
import { SwapOutlined, ZoomOutOutlined, ZoomInOutlined } from "@ant-design/icons";


let mockTemplates = [
  {
    name:"Performance Insights Dashboard",
    description:"Visualize key metrics and trends to monitor and optimize business performance across departments.",
    image:"https://generation-sessions.s3.amazonaws.com/faab6329155d5a297f2becc34c1d07cd/img/image-12@2x.png"
  },
  {
    name:"Sales Analytics Dashboard",
    description:"Comprehensive overview of sales data, customer behavior, and revenue patterns for strategic decision-making.",
    image:"https://generation-sessions.s3.amazonaws.com/faab6329155d5a297f2becc34c1d07cd/img/image-13@2x.png"
  },
  {
    name:"Financial Health Dashboard",
    description:"Track financial KPIs, budget allocation, and expense analysis to ensure fiscal stability and growth.",
    image:"https://generation-sessions.s3.amazonaws.com/faab6329155d5a297f2becc34c1d07cd/img/image-19@2x.png"
  },
  {
    name:"Marketing Campaign Dashboard",
    description:"Real-time insights into campaign reach, engagement, and ROI to refine marketing strategies effectively.",
    image:"https://generation-sessions.s3.amazonaws.com/faab6329155d5a297f2becc34c1d07cd/img/image-18-1@2x.png"
  },
  {
    name:"Employee Performance Dashboard",
    description:"Visualize performance metrics, skill development, and goal progress for employee growth and engagement.",
    image:"https://generation-sessions.s3.amazonaws.com/faab6329155d5a297f2becc34c1d07cd/img/image-15-1@2x.png"
  },
  {
    name:"Financial Health Dashboard",
    description:"Track financial KPIs, budget allocation, and expense analysis to ensure fiscal stability and growth.",
    image:"https://generation-sessions.s3.amazonaws.com/faab6329155d5a297f2becc34c1d07cd/img/image-21@2x.png"
  },
  {
    name:"Marketing Campaign Dashboard",
    description:"Real-time insights into campaign reach, engagement, and ROI to refine marketing strategies effectively.",
    image:"https://generation-sessions.s3.amazonaws.com/faab6329155d5a297f2becc34c1d07cd/img/image-18@2x.png"
  },
  {
    name:"Employee Performance Dashboard",
    description:"Visualize performance metrics, skill development, and goal progress for employee growth and engagement.",
    image:"https://generation-sessions.s3.amazonaws.com/faab6329155d5a297f2becc34c1d07cd/img/image-15@2x.png"
  },
  
]


const DashboardsTemplates = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredList, setFilteredList] = useState<Array<any>>([]);
  const [appContext] = useContext(AppContext)
  const { data: temp = [] } = useGetDashboardTemplatesQuery(undefined);
  const [previewTemplate, setPreviewTemplate] = useState<typeof mockTemplates[0] | null>(null);
  const [scaleStep, setScaleStep] = useState(0.5);

  let filters = [
    {name:"All"},
    {name:"Timesheets"},
    {name:"Payments"},
    {name:"Invoices"},
    {name:"Expenses"},
    {name:"Assignments"},
    {name:"Jobs"},
  ]

  const handleSearch = (term:string) => {
    const filtered = temp.filter(item =>
      item.name.toLowerCase().includes(term.toLowerCase())
    );
    let templates = filtered.map((template,i) => 
    {return {id:template.id,name:template.name,description:mockTemplates[i%mockTemplates.length].description,image:mockTemplates[i%mockTemplates.length].image,templateUrl:template.templateUrl}} 
    )
    setFilteredList(templates);
  };
  useEffect(()=>{handleSearch(searchTerm)},[temp,searchTerm])

  //will remove this after api's changes
  
  
  //
  
  const dispatch = useDispatch();

  useEffect(() => {},[]);

  return (
    <>
    <div className="create-wrapper p-24 px-m-0 py-m-0" style={{width: '100%', overflow:'auto'}}>
                <div className="create-form">
                  <div className="d-flex">
                    <i className="material-icons cursor-pointer mr-12" onClick={_ => dispatch(goBack())}>arrow_back</i>
                    <h3 className="font-weight-medium page-title-mobile">Create Dashboard</h3>
                  </div>
                <div className="card bg-light mt-24 mobile-card-view">
                  <div className="breadcrumb d-flex mobile-comp">
                    <div className="item pl-24 pr-24 active">
                      <div>
                        <span className="num mr-8">1</span>
                        <span className="title">Templates</span>
                      </div>
                    </div>
                    <div className="item pl-24 pr-24">
                      <div>
                        <span className="num mr-8">2</span>
                        <span className="title">Dashboard Details</span>
                      </div>
                    </div>
                  </div>
                  <div className="basic-info candidate-basic-info create p-24" style={{ overflow:'auto'}}>


                  <div className="analytics-wrapper">
      {/* <div className="sub-header p-24 bg-light d-flex align-items-center justify-content-between">
        <div className="d-flex">
        <i className="material-icons back-arrow-icon mr-16 cursor-pointer"  onClick={() => dispatch(goBack())}>arrow_back</i>
          <h3 className="font-weight-semibold mb-0">Create New Dashboard</h3>
        </div>
      </div> */}
      <div className="main-wrapper p-24">
        {/* <div className="side-filter-content">
          <span className="filter-list d-flex align-items-center text-uppercase font-weight-medium mb-16"><i className="material-icons mr-8">filter_list</i> Template Filters</span>
          <ul className="checkbox-item">
            {
              filters.map((f)=>(
                <li>
                    <label className="c-input-checkbox my-12">
                        <input type="checkbox" value={f.name}/>
                        <span className="cb-ui"></span>
                        <span  className="cb-label">{f.name}</span>
                    </label>
                </li>
              ))
            }
           
        </ul>
        </div> */}
        <div className="analytics-section-content">
          <div className="analytics-section-header d-flex align-items-center justify-content-between mb-24">
            <div className="header-text">
              <h3 className="font-weight-semibold">Dashboard Templates</h3>
              <p>pick any template to continue the dashboard creation</p>
            </div>
            <div className="report-search position-relative">
              <input type="text" placeholder="Search Templates" className="search-input" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}/>
              <i className="material-icons mr-16 cursor-pointer">manage_search</i>
            </div>
          </div>

          <div className="analytics-template-list mt-32">
            <ul className="dashboard-template-list">
              <li className="cursor-pointer dashed-border d-flex justify-content-center align-items-center">
                <div className="add-blank-template" style={{cursor:'pointer'}} onClick={() => dispatch(push(`${appContext.basepath}/dashboards/add`))}>
                  <i className="material-icons" >add</i>
                  <p>Blank Template</p>
                </div>
              </li>
              {
                filteredList?.map((template:any)=>(
                  <li>
                    <figure>
                    <Image
                      width={291}
                      style={{ display: 'none' }}
                      preview={{
                        visible: previewTemplate === template,
                        src: template.image,
                        onVisibleChange: (value) => {
                          setPreviewTemplate(null);
                        },
                        toolbarRender: (
                          _,
                          {
                            transform: { scale },
                            actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
                          },
                        ) => (
                          <Space size={12} className="toolbar-wrapper">
                            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                          </Space>
                        )
                      }}
                    />
                      <img src={template.image} alt="" width="291" height="155"/>
                      <button onClick={_ => setPreviewTemplate(template)}>
                        <i className="material-icons">preview</i>
                      </button>
                    </figure>
                    <div className="p-12">
                      <div className="templte-caption text-left pb-16">
                        <p className="font-weight-medium text-black">{template.name}</p>
                        <p className="sub-text">{template.description}
                        </p>
                      </div>
                      <div className="template-footer d-flex justify-content-between pt-16">
                        <button type="button" className="font-weight-medium" style={{cursor:'pointer'}} onClick={() => dispatch(replace(`${appContext.basepath}/dashboards/add?${new URLSearchParams({templateId:template.id}).toString()}`))}>Use This Template</button>
                        {/* <i className="material-icons cursor-pointer">bookmark_border</i> */}
                      </div>
                    </div>
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
      </div>
    </div>


                  <div>



    

    

    </div>
    </div>
    
  </div>

  </div>
</div>
    </>
  );
};

export default DashboardsTemplates;
