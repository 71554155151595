import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { layoutSlice } from "../../layout/store/layout-slice";
import { AppContext } from "../../app/store";
import { push } from "redux-first-history";

const CreateNew = () => {
  const dispatch = useDispatch();
  const [appContext] = useContext(AppContext)
  return (
    <div className="analytics-wrapper" style={{width:"100%"}}>
        <div className="landing-page-wrapper d-flex justify-content-center align-items-center">
            <div className="landing-page theme2">
                <h2 className="font-weight-semibold mb-24 text-center">Create New</h2>
                <ul>
                    <li>
                        {/* <div className="landing-page-item cursor-pointer d-flex align-items-center justify-content-between p-24 mb-16" onClick={() => dispatch(push(`${appContext.basepath}/reports/reports-templates`))}> */}
                        <div className="landing-page-item  cursor-pointer d-flex align-items-center justify-content-between p-24 mb-16" onClick={() => dispatch(push(`${appContext.basepath}/reports/add`))}>
                            <div className="landing-list-content d-flex align-items-center">
                                <figure className="mr-24">
                                    <svg width="105" height="105" viewBox="0 0 105 105" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="105" height="105" rx="52.5" fill="#01CA17"/>
                                        <path d="M41.1167 77.0436L39.2884 75.2154L55.325 59.1639L64.3667 68.2056L77.7354 54.8368L79.5786 56.6799L64.3667 71.8769L55.325 62.8353L41.1167 77.0436ZM33.9231 73.6654C32.7341 73.6654 31.7413 73.2671 30.9448 72.4706C30.1483 71.674 29.75 70.6813 29.75 69.4923V36.5051C29.75 35.3161 30.1483 34.3234 30.9448 33.5268C31.7413 32.7303 32.7341 32.332 33.9231 32.332H66.9103C68.0993 32.332 69.092 32.7303 69.8885 33.5268C70.6851 34.3234 71.0833 35.3161 71.0833 36.5051V47.3551H32.3333V69.4923C32.3333 69.8897 32.4989 70.254 32.8301 70.5853C33.1613 70.9164 33.5256 71.082 33.9231 71.082V73.6654ZM32.3333 44.7717H68.5V36.5051C68.5 36.1077 68.3344 35.7434 68.0032 35.4121C67.672 35.081 67.3077 34.9154 66.9103 34.9154H33.9231C33.5256 34.9154 33.1613 35.081 32.8301 35.4121C32.4989 35.7434 32.3333 36.1077 32.3333 36.5051V44.7717Z" fill="white"/>
                                    </svg>

                                    <img src="https://generation-sessions.s3.amazonaws.com/e03dfc11266d683f373deda13a6d5bc7/img/seo-report-1@2x.png" alt="" width="55" height="55"/>    
                                </figure>
                                <div className="landing-list-caption">
                                    <h3 className="font-weight-semibold">Report</h3>
                                    <p>A report is a concise document conveying data, analysis, and conclusions on a subject, aiding informed decisions through structured communication.</p>
                                </div>
                            </div>
                            <a>Create<i className="material-icons ml-24">east</i></a>
                        </div>
                    </li>
                    <li>
                        <div className="landing-page-item  cursor-pointer d-flex align-items-center justify-content-between p-24 mb-16" onClick={() => dispatch(push(`${appContext.basepath}/dashboards/add`))}>
                            <div className="landing-list-content d-flex align-items-center">
                                <figure className="mr-24">
                                    <svg width="105" height="105" viewBox="0 0 105 105" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="105" height="105" rx="52.5" fill="#0055FF"/>
                                    <path d="M56.75 45.5V33H73V45.5H56.75ZM33 53V33H49.25V53H33ZM56.75 73V53H73V73H56.75ZM33 73V60.5H49.25V73H33ZM35.5 50.5H46.75V35.5H35.5V50.5ZM59.25 70.5H70.5V55.5H59.25V70.5ZM59.25 43H70.5V35.5H59.25V43ZM35.5 70.5H46.75V63H35.5V70.5Z" fill="white"/>
                                    </svg>

                                    <img src="https://generation-sessions.s3.amazonaws.com/e03dfc11266d683f373deda13a6d5bc7/img/speedometer-1@2x.png" alt="" width="55" height="55"/>    
                                </figure>
                                <div className="landing-list-caption">
                                    <h3 className="font-weight-semibold">Dashboard</h3>
                                    <p>A report dashboard is a visual presentation of key data and metrics, providing an overview for quick insights and analysis.</p>
                                </div>
                            </div>
                            <a>Create<i className="material-icons ml-24">east</i></a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
  );
};

export default CreateNew;
