import { Link } from "../../../types/layout";
import { EnumDictionary, getItem } from "../../../layout/main-layout-sider";
import { RiseOutlined } from "@ant-design/icons";
import { PERMISSIONS } from "../../auth/permissions/permission-maps";
import { hasPermission } from "../../auth/store/auth-slice";
import { store } from "../../../app/store";

export enum DashboardsListType {
  Recent,
  CreatedByMe,
  Private,
  Public,
  SharedWithMe,
  All,
}

export enum HeaderDashboardButtons {
  NewDashboard = 1,
  NewSchedule,
  SaveNewDashboard,
  SaveAsDraftNewDashboard,
  CancelNewDashboard,

  SaveDashboardSchedule,
  CancelDashboardSchedule,
  ViewAllSchedules,
  ViewAllDashboards,
  ViewAllReports,

  ExportOptions,
  // ExportJson,
  ExportExcel,
  ExportCSV,

  MoreOptions = 20,

  EditPBIDashboard = 40,
  ExitEditPBIDashboard,
  Clone,
  saveChanges,
}

export const links: EnumDictionary<DashboardsListType, Link> = {
  [DashboardsListType.Recent]: {
    label: "Recent",
    link: "/dashboards/recent",
    permission: PERMISSIONS.listDashboards,
  },
  [DashboardsListType.CreatedByMe]: {
    label: "Created By Me",
    link: "/dashboards/createdByMe",
    permission: PERMISSIONS.listDashboards,
  },
  [DashboardsListType.Private]: {
    label: "Private Dashboards",
    link: "/dashboards/private",
    permission: PERMISSIONS.listDashboards,
  },
  [DashboardsListType.Public]: {
    label: "Public Dashboards",
    link: "/dashboards/public",
    permission: PERMISSIONS.listDashboards,
  },
  [DashboardsListType.SharedWithMe]: {
    label: "Shared Dashboards",
    link: "/dashboards/sharedWithMe",
    permission: PERMISSIONS.listDashboards,
  },
  [DashboardsListType.All]: {
    label: "All Dashboards",
    link: "/dashboards/all",
    permission: PERMISSIONS.listDashboards,
  },
};

export function getMenuItems() {
  return [
    hasPermission(store.getState(), PERMISSIONS.listDashboards)
      ? getItem(
          <span>
            <RiseOutlined style={{ marginRight: "4px" }} />
            Dashboards
          </span>,
          "dashboards",
          null,
          Object.entries(links)
            .filter(([k, v]) => hasPermission(store.getState(), v.permission))
            .map(([k, v]) => getItem(v.label, k)),
          "group"
        )
      : null,
  ];
}
