import { useState, useContext, useEffect, useRef, useId } from "react";
import { Breadcrumb, Button, Dropdown, Space, Tabs, Tag, Tooltip, Typography,Modal } from "antd";
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  ScheduleOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { push } from "redux-first-history";
import {
  useAddReportBookmarkMutation,
  useAddTenantToStandardReportMutation,
  useDeleteReportBookmarkMutation,
  useDeleteReportMutation,
  useGetAllReportsQuery,
  useGetPrivateReportsQuery,
  useGetPublicReportsQuery,
  useGetRecentReportsQuery,
  useGetReportsCreatedByMeQuery,
  useGetSharedReportsQuery,
} from "./store/report-api-slice";
import type { ColumnsType } from "antd/es/table";
import { Report } from "../../types/report";
import { useParams, useSearchParams } from "react-router-dom";
import { HeaderReportButtons, ReportsListType, TenantsListType } from "./layout/reports-config";
import { AppContext, store, useAppDispatch } from "../../app/store";
import { BreadcrumbItem, layoutSlice } from "../../layout/store/layout-slice";
import List, { TableParams } from "../../layout/components/list";
import { ModalUtility } from "../../layout/components/modal-manager";
import { IShareModal, ShareType } from "../users/components/share-modal";
import { CloneType, ICloneModal } from "../../layout/components/clone-modal";
import { hasPermission } from "../auth/store/auth-slice";
import { PERMISSIONS } from "../auth/permissions/permission-maps";
import { useEffectOnce } from "../../hooks/useEffectOnce";
import { ItemType } from "antd/es/menu/hooks/useItems";

import './reports-listing.css'
import { useGetFolderQuery } from "../folders/store/folder-api-slice";
import { useGetAllAdminStandardReportsQuery } from "./store/report-api-slice";
import { useGetTenantStandardReportsQuery } from "./store/report-api-slice";

const {Text, Link} = Typography;

type props = {
  type: TenantsListType;
};

// TOOD: remove pageTitle mapping 
const pageTypeTitleMap = new Map<ReportsListType, string>()
pageTypeTitleMap.set(ReportsListType.All, "All Reports")
pageTypeTitleMap.set(ReportsListType.CreatedByMe, "My Reports")
pageTypeTitleMap.set(ReportsListType.Private, "Private Reports")
pageTypeTitleMap.set(ReportsListType.Public, "Public Reports")
pageTypeTitleMap.set(ReportsListType.Recent, "Recent Reports")
pageTypeTitleMap.set(ReportsListType.SharedWithMe, "Shared Reports")

// const getBookmarks = () => {
//   const bookmarks = localStorage.getItem('reports-bookmarks');
//   if(bookmarks){
//     return new Set<string>(JSON.parse(bookmarks));
//   }
//   else{
//     return new Set<string>();
//   }
// }
const getArchives = () => {
  const archives = localStorage.getItem('reports-archives');
  if(archives){
    return new Set<string>(JSON.parse(archives));
  }
  else{
    return new Set<string>();
  }
}


export const TenantsList = ({ type }: props) => {
  const { folderId } = useParams();
  const dispatch = useAppDispatch();
  const [appContext] = useContext(AppContext);
  const [params, ] = useSearchParams();
  const [columns, setColumns] = useState<ColumnsType<Report>>([]);
  // const [bookmarks, setBookmarks] = useState<Set<string>>(getBookmarks());
  const [archives, setArchives] = useState<Set<string>>(getArchives());
  const [addReportBookmark] = useAddReportBookmarkMutation()
  const [deleteReportBookmark] = useDeleteReportBookmarkMutation()
  const [addTenantToStandardReport] = useAddTenantToStandardReportMutation()

  const addToBookmarks = (reportId:number) => {
    addReportBookmark(reportId)
    //add an report array to local storage
    // let bookmarks = localStorage.getItem('bookmarks');
    
    // setBookmarks(bookmarks =>{
    //   bookmarks.add(reportId.toString());
    //   localStorage.setItem('reports-bookmarks', JSON.stringify(Array.from(bookmarks)));
    //   return new Set<string>(Array.from(bookmarks));
    // });
  }

  const removeBookmark = (reportId:number) => {
    deleteReportBookmark(reportId)
    // setBookmarks(bookmarks => {
    //   bookmarks.delete(reportId.toString());
    //   localStorage.setItem('reports-bookmarks', JSON.stringify(Array.from(bookmarks)));
    //   return new Set<string>(Array.from(bookmarks));
    // });
  }

  // const addToArchives = (reportId:number) => {
  //   //add an report array to local storage
  //   // let bookmarks = localStorage.getItem('bookmarks');
    
  //   setArchives(archives =>{
  //     archives.add(reportId.toString());
  //     localStorage.setItem('reports-archives', JSON.stringify(Array.from(archives)));
  //     return new Set<string>(Array.from(archives));
  //   });
  // }

  // const removeArchives = (reportId:number) => {
  //   setArchives(archives => {
  //     archives.delete(reportId.toString());
  //     localStorage.setItem('reports-archives', JSON.stringify(Array.from(archives)));
  //     return new Set<string>(Array.from(archives));
  //   });
  // }

  const getHeaderTitle = (subtitle: string) => {
    
      const headerItems: Array<BreadcrumbItem> = [
        {
          $type: "BreadcrumbItem",
          title: "Standard Reports",
          link: ''
        },
        // {
        //   $type: "BreadcrumbItem",
        //   title: subtitle,
        //   link: ''
        // },
      ]
      return headerItems
  }

  const setMyColumns = () =>{
    
      setColumns([
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          sorter: true,
          ellipsis: {
            showTitle: false,
          },
          render: (name, data) => {
    
            return (
              <div>
                <Link href={`${appContext.basepath}/standard-reports-view/${data.id}`} onClick={(e) => gotoLink(e, data)}>{name}</Link>
              </div>
            )
    
            
            // <Tooltip className="td-name" placement="topLeft" title={name}>
            //   <Link target="_blank">{name}</Link>
            // </Tooltip>
          },
        },
        {
          title: "Description",
          key: "description",
          dataIndex: "description",
          sorter: true,
          render: (description: string) =>
            description ? (
              <Tooltip placement="topLeft" title={description}>
                {description}
              </Tooltip>
            ) : (
              "--"
            ),
        },
        {
            title: "Category",
            key: "category",
            dataIndex: "category",
            sorter: true,
            render: (category: string) =>
            category ? (
              <Tooltip placement="topLeft" title={category}>
                {category}
              </Tooltip>
            ) : (
              "--"
            ),
        },
        {
          title: "Created By",
          dataIndex: "createdBy",
          key: "createdBy",
          sorter: true,
          ellipsis: {
            showTitle: false,
          },
          render: (createdBy) => (
            <Tooltip className="td-owner" placement="topLeft" title={createdBy}>
              {createdBy}
            </Tooltip>
          ),
        },
        {
          title: "Action",
          key: "action",
          render: (_, report) => (
            <Space size="middle">
              {hasPermission(store.getState(), PERMISSIONS.viewReport) ? (
                <Tooltip title="View">
                  <Button
                    icon={<EyeOutlined />}
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(push(`${appContext.basepath}/standard-reports-view/${report.id}`));
                      return false;
                    }}
                  />
                </Tooltip>
              ) : (
                ""
              )}
    
              {hasPermission(store.getState(), [
                PERMISSIONS.viewReport,
                PERMISSIONS.editReport,
                PERMISSIONS.deleteReport,
                PERMISSIONS.scheduleReport,
                PERMISSIONS.cloneReport,
                PERMISSIONS.shareReport,
              ]) ? (
                <Dropdown.Button
                  type="default"
                  menu={{
                    items: [
                      hasPermission(store.getState(), PERMISSIONS.editReport)
                        ? {
                            key: 1,
                            label: "Edit Details",
                            disabled: !report.allowEdit,
                            icon: <EditOutlined />,
                            onClick: () =>
                              dispatch(push(`${appContext.basepath}/tenants/add`)),
                          }
                        : null,
                      hasPermission(store.getState(), PERMISSIONS.deleteReport)
                        ? {
                            key: 2,
                            label: "Delete",
                            disabled: !report.allowEdit,
                            icon: <DeleteOutlined />,
                            onClick: async () =>{
                              Modal.confirm({
                                title: 'Confirmation',
                                content: `Are you sure you want to add ${report.name} report to Archive?`,
                                okText:'Confirm',
                                icon:' ',
                                onOk: async () => await deleteReport(report.id),
                                onCancel: () =>{}
                              });
                            }
                               
                          }
                        : null,
                      hasPermission(store.getState(), PERMISSIONS.createReport)
                        ? {
                            key: 3,
                            label: "Add Tenant to Report",
                            disabled: !report.allowEdit,
                            icon: <EditOutlined />,
                            onClick: async () =>{
                              const tenantId = await new Promise((resolve) => {
                                Modal.confirm({
                                  title: 'Enter Tenant ID',
                                  content: (
                                    <div>
                                      <label>Tenant ID:</label>
                                      <input type="text" id="tenantIdInput" />
                                    </div>
                                  ),
                                  onOk: () => {
                                    const tenantIdInput = document.getElementById('tenantIdInput') as HTMLInputElement;
                                    resolve({ tenantId: tenantIdInput.value, reportId: report.id });
                                  },
                                  onCancel: () => resolve({ tenantId: null }),
                                  okText: 'Add',
                                });
                              });
                      
                              if (tenantId) {
                                // Perform your POST API request here using the 'tenantId'
                                await addTenantToStandardReport(tenantId);
                              }
                            }
                               
                          }
                        : null,
                    //   hasPermission(store.getState(), PERMISSIONS.viewReport) && !report.isBookmarked
                    //     ? {
                    //         key: 3,
                    //         label: "Add to Bookmarks",
                    //         icon: <span className="material-symbols-outlined" style={{cursor:'pointer',fontSize:'16px',marginLeft:"-2px"}}>bookmark_add</span>,
                    //         onClick: () => {
                    //           Modal.confirm({
                    //             title: 'Confirmation',
                    //             content: `Are you sure you want to add ${report.name} report to bookmarks?`,
                    //             okText:'Confirm',
                    //             icon:' ',
                    //             onOk: () => addToBookmarks(report.id),
                    //             onCancel: () => {}, 
                    //           });
                    //         },
                    //       }
                    //     : null,
                    //     hasPermission(store.getState(), PERMISSIONS.viewReport) && report.isBookmarked
                    //     ? {
                    //         key: 33,
                    //         label: "Remove From Bookmarks",
                    //         icon: <span className="material-symbols-outlined" style={{cursor:'pointer',fontSize:'16px',marginLeft:"-2px"}}>bookmark_remove</span>,
                    //         onClick: () => {
                    //           Modal.confirm({
                    //             title: 'Confirmation',
                    //             content: `Are you sure you want to remove this ${report.name} from bookmarks?`,
                    //             okText:'Confirm',
                    //             icon:' ',
                    //             onOk: () => removeBookmark(report.id),
                    //             onCancel: () => {}, 
                    //           });
                    //         },
                    //       }
                    //     : null,

                      // hasPermission(store.getState(), PERMISSIONS.viewReport) && !archives.has(report.id.toString())
                      //   ? {
                      //       key: 34,
                      //       label: "Archive",
                      //       icon: <span className="material-symbols-outlined" style={{cursor:'pointer',fontSize:'16px',marginLeft:"-2px"}}>archive</span>,
                      //       onClick: () => addToArchives(report.id),
                      //     }
                      //   : null,
                      //   hasPermission(store.getState(), PERMISSIONS.viewReport) && archives.has(report.id.toString())
                      //   ? {
                      //       key: 35,
                      //       label: "Unarchive",
                      //       icon: <span className="material-symbols-outlined" style={{cursor:'pointer',fontSize:'16px',marginLeft:"-2px"}}>unarchive</span>,
                      //       onClick: () => removeArchives(report.id),
                      //     }
                      //   : null,

                    //   ...(hasPermission(
                    //     store.getState(),
                    //     PERMISSIONS.scheduleReport
                    //   )
                    //     ? [
                    //         { type: "divider" } as ItemType,
                    //         {
                    //           key: 4,
                    //           label: "New Schedule",
                    //           icon: <ScheduleOutlined />,
                    //           onClick: () =>
                    //             dispatch(
                    //               push(`${appContext.basepath}/reports/${report.id}/schedules/add`)
                    //             ),
                    //         },
                    //         {
                    //           key: 5,
                    //           label: "View Schedules",
                    //           icon: <ScheduleOutlined />,
                    //           onClick: () =>
                    //             dispatch(push(`${appContext.basepath}/reports/${report.id}/schedules`)),
                    //         },
                    //       ]
                    //     : []),
                    //   ...(hasPermission(store.getState(), PERMISSIONS.cloneReport)
                    //     ? [
                    //         { type: "divider" } as ItemType,
                    //         {
                    //           key: 6,
                    //           label: "Clone",
                    //           icon: <CopyOutlined />,
                    //           onClick: () => cloneShareModal(report.id),
                    //         },
                    //       ]
                    //     : []),
                      // ...(hasPermission(store.getState(), PERMISSIONS.shareReport)
                      //   ? [
                      //       { type: "divider" } as ItemType,
                      //       {
                      //         key: PERMISSIONS.shareReport,
                      //         label: "Share",
                      //         disabled: !report.private || !report.createdByMe,
                      //         icon: <ShareAltOutlined />,
                      //         onClick: () => showShareModal(report.id),
                      //       },
                      //     ]
                      //   : []),
                    ],
                  }}
                ></Dropdown.Button>
              ) : null}
            </Space>
          ),
        }
      ])
    
  }

  const {data: folderDetails} = useGetFolderQuery<{data: {name: string}}>(+(folderId || "0"), {
    skip: !folderId,
  });

  useEffectOnce(() => {
    setMyColumns();
    dispatch(layoutSlice.actions.updateShowSider(false));
    dispatch(layoutSlice.actions.updateShowSubHeader(false));
    // dispatch(layoutSlice.actions.updateShowGoBackButton(false));
    
  });

  useEffect(() => {

    
    dispatch(layoutSlice.actions.updateTitle(getHeaderTitle(folderId ? (folderDetails?.name || params.get('folderName')||'') : '')));
    

    dispatch(layoutSlice.actions.updateSetLayoutClass("listing-page"));
    dispatch(layoutSlice.actions.updateSubTitle(``));

    dispatch(layoutSlice.actions.updateAddNewUrl(`${appContext.basepath}/tenants/add`));
    
    let buttons: Array<HeaderReportButtons> = [
      // HeaderReportButtons.NewReport
    ];
    
    // buttons = [...buttons, HeaderReportButtons.EditFolder];

    buttons = [
      ...buttons,
      // HeaderReportButtons.ViewAllDashboards,
      // HeaderReportButtons.ViewAllSchedules,
      // HeaderReportButtons.MoreOptions,
    ];
    dispatch(layoutSlice.actions.updateButtonsToShow(buttons));

    return function () {
      dispatch(layoutSlice.actions.updateSetLayoutClass(""))
      dispatch(layoutSlice.actions.updateAddNewUrl(``));
    }
  }, [folderId, folderDetails]);


  // const shareModalRef = ModalUtility.getPropertyByName("shareModalRef");
  const cloneModalRef = ModalUtility.getPropertyByName("cloneModalRef");

  // const showShareModal = (reportId: number) => {
  //   (shareModalRef.current as IShareModal)?.changeId(reportId);
  //   (shareModalRef.current as IShareModal)?.changeType(ShareType.Report);
  //   shareModalRef?.current?.toggleModal();
  // };

  const cloneShareModal = (reportId: number) => {
    (cloneModalRef.current as ICloneModal)?.changeId(reportId);
    (cloneModalRef.current as ICloneModal)?.changeType(CloneType.Report);
    cloneModalRef?.current?.toggleModal();
  };

  const [deleteReport] = useDeleteReportMutation();

  const gotoLink = (e: any, data: any) => {
    e.preventDefault();
    console.log(data)
    dispatch(push(`${appContext.basepath}/standard-reports-view/${data.id}`))
  }

  

  /** Pagination */
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const { data: allReports } = useGetAllAdminStandardReportsQuery(
    {
      page: tableParams.pagination?.current || 1,
      per_page: tableParams.pagination?.pageSize || 10,
      sortBy: tableParams.sortField,
      sortOrder: tableParams.sortOrder,
      folderId: folderId,
    },
    {
      skip: !(type === TenantsListType.All),
    }
  );

  var reports: ListResponse<Report>;

  switch (type) {
    case TenantsListType.All:
      reports = allReports!;
      break;

    // case ReportsListType.Recent:
    //   reports = recentReports!;
    //   break;

    // case ReportsListType.CreatedByMe:
    //   reports = reportsCreatedByMe!;
    //   break;

    // case ReportsListType.Private:
    //   reports = privateReports!;
    //   break;

    // case ReportsListType.Public:
    //   reports = publicReports!;
    //   break;

    // case ReportsListType.SharedWithMe:
    //   reports = sharedReports!;
    //   break;
  }

  
  useEffect(() => {
    dispatch(layoutSlice.actions.updateSubTitle(folderId ? `${reports?.items?.length||0} Records` : ''));

  }, [type, folderId, reports])

  return (<List columns={columns} data={reports} setTableParams={setTableParams}  />);

};

export default TenantsList;