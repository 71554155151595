import { useState, useContext, useEffect, useRef, useId } from "react";
import { Breadcrumb, Button, Dropdown, Space, Tabs, Tag, Tooltip, Typography, Modal } from "antd";
import {
  CopyOutlined,
  DeleteOutlined,
  DownSquareFilled,
  EditOutlined,
  EyeOutlined,
  ScheduleOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { push } from "redux-first-history";
import {
  useDeleteReportMutation,
  useGetAllReportsQuery,
  useGetArchivedReportsQuery,
  useGetPrivateReportsQuery,
  useGetPublicReportsQuery,
  useGetRecentReportsQuery,
  useGetReportsCreatedByMeQuery,
  useGetSharedReportsQuery,
  useUnArchiveReportMutation,
} from "./store/report-api-slice";
import type { ColumnsType } from "antd/es/table";
import { Report } from "../../types/report";
import { useParams } from "react-router-dom";
import { HeaderReportButtons } from "./layout/reports-config";
import { AppContext, store, useAppDispatch } from "../../app/store";
import { layoutSlice } from "../../layout/store/layout-slice";
import List, { TableParams } from "../../layout/components/list";
import { ModalUtility } from "../../layout/components/modal-manager";
import { IShareModal, ShareType } from "../users/components/share-modal";
import { CloneType, ICloneModal } from "../../layout/components/clone-modal";
import { hasPermission } from "../auth/store/auth-slice";
import { PERMISSIONS } from "../auth/permissions/permission-maps";
import { useEffectOnce } from "../../hooks/useEffectOnce";
import { ItemType } from "antd/es/menu/hooks/useItems";

import './archive-listing.css'
import { useDeleteDashboardMutation, useGetAllDashboardsQuery, useGetArchivedDashboardsQuery, useUnArchiveDashboardMutation } from "../dashboards/store/dashboard-api-slice";
import { Dashboard } from "../../types/dashboard";

const {Text, Link} = Typography;

export enum ArchiveListType {
  Reports = 1,
  Dashboards
}

type props = {
  type: ArchiveListType;
};
// const getReportArchives = () => {
//   const archives = localStorage.getItem('reports-archives');
//   if(archives){
//     return new Set<string>(JSON.parse(archives));
//   }
//   else{
//     return new Set<string>();
//   }
// }
// const getDashboardArchives = () => {
//   const archives = localStorage.getItem('dashboard-archives');
//   if(archives){
//     return new Set<string>(JSON.parse(archives));
//   }
//   else{
//     return new Set<string>();
//   }
// }
export const ArchiveList = ({ type }: props) => {
  const { folderId } = useParams();
  const dispatch = useAppDispatch();
  const [appContext] = useContext(AppContext);
  const [unArchiveDashboard] = useUnArchiveDashboardMutation();
  const [unArchiveReport] = useUnArchiveReportMutation();
  const [columns, setColumns] = useState<any>();


  const getHeaderTitle = () => {
      return "Archives"
  }

  // const [reportArchives, setReportArchives] = useState<Set<string>>(getReportArchives());
  // const [dashboardArchives, setDashboardArchives] = useState<Set<string>>(getDashboardArchives());
  const removeReportArchive = (reportId:number) => {
    unArchiveReport({reportId})
    // setReportArchives(archives => {
    //   archives.delete(reportId.toString());
    //   localStorage.setItem('reports-archives', JSON.stringify(Array.from(archives)));
    //   return new Set<string>(Array.from(archives));
    // });
  }
  const removeDashboardArchive = (dashboardId:number) => {
    unArchiveDashboard({dashboardId})
    // setDashboardArchives(archives => {
    //   archives.delete(reportId.toString());
    //   localStorage.setItem('dashboard-archives', JSON.stringify(Array.from(archives)));
    //   return new Set<string>(Array.from(archives));
    // });
  }

  useEffectOnce(() => {

    dispatch(layoutSlice.actions.updateTitle(getHeaderTitle()));
    dispatch(layoutSlice.actions.updateShowSider(false));
    dispatch(layoutSlice.actions.updateShowSubHeader(false));
    dispatch(layoutSlice.actions.updateShowGoBackButton(false));
    });
    useEffect(() => {
    dispatch(layoutSlice.actions.updateSetLayoutClass("listing-page"));
    dispatch(layoutSlice.actions.updateSubTitle(''));
    let buttons = [HeaderReportButtons.NewReport];
    if (folderId) {
      buttons = [...buttons, HeaderReportButtons.EditFolder];
    }

    buttons = [
    //   ...buttons,
    //   // HeaderReportButtons.ViewAllDashboards,
    //   // HeaderReportButtons.ViewAllSchedules,
    //   HeaderReportButtons.MoreOptions,
    ];
    dispatch(layoutSlice.actions.updateButtonsToShow(buttons));
 
    return function () {
      dispatch(layoutSlice.actions.updateSetLayoutClass(""))
    }
  }, []);

  useEffect(() => {
    dispatch(layoutSlice.actions.updateTitle(getHeaderTitle()));
    changeColumnType(type);
  }, [type, folderId])

  const changeColumnType = (type: ArchiveListType) => {
    if(type===ArchiveListType.Dashboards) {
      setColumns([
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          sorter: true,
          ellipsis: {
            showTitle: false,
          },
          render: (name:any, data:any) => (
             <div style={{display:'flex',justifyContent:'space-between',paddingRight:"4.15px"}}>
                <Link href={`${appContext.basepath}/dashboards/${data.id}`} onClick={(e) => dashboardLink(e, data)} style={{fontWeight: '500', fontSize: '13px', lineHeight: '15.73px'}}>{name}</Link>
                {/* <DownSquareFilled style={{color:"#0055FF",fontSize:"15px"}}/> */}
              </div>
            // <Tooltip placement="topLeft" className="td-name" title={name}>
            //   {name}
            // </Tooltip>
          ),
        },
        {
          title: "Description",
          key: "description",
          dataIndex: "description",
          sorter: true,
          render: (description: string) =>
            description ? (
              <Tooltip placement="topLeft" title={description}>
                {description}
              </Tooltip>
            ) : (
              "--"
            ),
        },
        {
          title: "Owner",
          dataIndex: "createdBy",
          key: "createdBy",
          sorter: true,
          ellipsis: {
            showTitle: false,
          },
          render: (createdBy:any) => (
            <Tooltip placement="topLeft" className="td-owner" title={createdBy}>
              {createdBy}
            </Tooltip>
          ),
        },
        {
          title: "Action",
          key: "action",
          render: (_:any, dashboard:any) => (
            <Space size="middle">
              <Tooltip title="Unarchive">
                <Button
                    style={{paddingRight:0,paddingLeft:0,width:'32px'}}
                  onClick={(e) => {
                    e.preventDefault();
                    Modal.confirm({
                      title: 'Remove from Archive',
                      content: `Are you sure you want to remove ${dashboard.name} from Archive?`,
                      okText: 'Confirm',
                      icon: ' ',
                      onOk: () => 
                      removeDashboardArchive(dashboard.id),
                      onCancel: () => {}, 
                    });
              
                    return false;
                  }}
                >
                    <span className="material-symbols-outlined" style={{cursor:'pointer'}}>unarchive</span>
                  </Button>
              </Tooltip>
            </Space>
          ),
        },
      ])
    }
    else{
      setColumns([
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          sorter: true,
          ellipsis: {
            showTitle: false,
          },
          render: (name:any, data:any) => {
    
            return (
              <div style={{display:'flex',justifyContent:'space-between',paddingRight:"4.15px"}}>
                <Link href={`${appContext.basepath}/reports/${data.id}`} onClick={(e) => gotoLink(e, data)}>{name}</Link>
                {/* <DownSquareFilled style={{color:"#0055FF",fontSize:"15px"}}/> */}
              </div>

            )
    
            
            // <Tooltip className="td-name" placement="topLeft" title={name}>
            //   <Link target="_blank">{name}</Link>
            // </Tooltip>
          },
        },
        {
          title: "Description",
          key: "description",
          dataIndex: "description",
          sorter: true,
          render: (description: string) =>
            description ? (
              <Tooltip placement="topLeft" title={description}>
                {description}
              </Tooltip>
            ) : (
              "--"
            ),
        },
        // {
        //   title: "Folder",
        //   key: "folder",
        //   dataIndex: "folder",
        //   sorter: true,
        //   render: (folder: string) => ((folder || "")),
        // },
        
        {
          title: "Owner",
          dataIndex: "createdBy",
          key: "createdBy",
          sorter: true,
          ellipsis: {
            showTitle: false,
          },
          render: (createdBy:any) => (
            <Tooltip className="td-owner" placement="topLeft" title={createdBy}>
              {createdBy}
            </Tooltip>
          ),
        },
        {
          title: "Action",
          key: "action",
          render: (_:any, report:any) => (
            <Space size="middle">
                <Tooltip title="Unarchive">
                  <Button
                    style={{paddingRight:0,paddingLeft:0,width:'32px'}}
                  onClick={(e) => {
                    e.preventDefault();
                    Modal.confirm({
                      title: 'Remove from Archive',
                      content: `Are you sure you want to remove ${report.name} from Archive?`,
                      okText: 'Confirm',
                      icon: ' ',
                      onOk: () => 
                      removeReportArchive(report.id),
                      onCancel: () => {}, 
                    });
              
                    return false;
                  }}
                >
                    <span className="material-symbols-outlined" style={{cursor:'pointer'}}>unarchive</span>
                  </Button>
                </Tooltip>
            </Space>
          ),
        },
      ])
    }
  }
  const shareModalRef = ModalUtility.getPropertyByName("shareModalRef");
  const cloneModalRef = ModalUtility.getPropertyByName("cloneModalRef");

  const showShareModal = (reportId: number) => {
    (shareModalRef.current as IShareModal)?.changeId(reportId);
    (shareModalRef.current as IShareModal)?.changeType(ShareType.Report);
    shareModalRef?.current?.toggleModal();
  };

  const cloneShareModal = (reportId: number) => {
    (cloneModalRef.current as ICloneModal)?.changeId(reportId);
    (cloneModalRef.current as ICloneModal)?.changeType(CloneType.Report);
    cloneModalRef?.current?.toggleModal();
  };

  const [deleteReport] = useDeleteReportMutation();

  const gotoLink = (e: any, data: any) => {
    e.preventDefault();
    // console.log(data)
    dispatch(push(`${appContext.basepath}/reports/${data.id}`))
  }
  const dashboardLink = (e: any, data: any) => {
    e.preventDefault();
    // console.log(data)
    dispatch(push(`${appContext.basepath}/dashboards/${data.id}`))
  }

  

  /** Pagination */
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [tableParamsDashboard, setTableParamsDashboard] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const { data: allReports } = useGetArchivedReportsQuery(
    {
      page: tableParams.pagination?.current || 1,
      per_page: tableParamsDashboard.pagination?.pageSize || 10
    },
    {
      skip: !(type === ArchiveListType.Reports),
    }
  );

  const { data: allDashboards } = useGetArchivedDashboardsQuery(
    {
      page: tableParamsDashboard.pagination?.current || 1,
      per_page: tableParamsDashboard.pagination?.pageSize || 10
    },
    {
      skip: !(type === ArchiveListType.Dashboards),
    }
  );

  var archiveList: any;

  switch (type) {
    case ArchiveListType.Reports:
      archiveList = allReports!;
      // if(archiveList) {
      //   archiveList = {...archiveList,pageSize:100,totalCount:100, items: archiveList.items.filter((report: Report) => reportArchives.has(report.id.toString()))}
      // }
      break;
    case ArchiveListType.Dashboards:
      archiveList = allDashboards!;
      // if(archiveList) {
      //   archiveList = {...archiveList,pageSize:100,totalCount:100, items: archiveList.items.filter((dashboard: Dashboard) => dashboardArchives.has(dashboard.id.toString()))}
      // }
      break;
  }
  return (
    <>
    <Tabs
      defaultActiveKey={type === ArchiveListType.Reports ? "reports" : "dashboards"}
      onChange={event => {
        dispatch(push(`${appContext.basepath}/archive/${event}`));
      }}
      destroyInactiveTabPane={true}
      tabBarStyle={{marginBottom: 0}}
      style={{height: '100%', overflow: 'hodden'}}
      items={[
        {
          label: 'Reports',
          key: "reports",
          children: <List columns={columns} data={archiveList} setTableParams={setTableParams}  />
        },
        {
          label: 'Dashboards',
          key: "dashboards",
          children: <List columns={columns} data={archiveList} setTableParams={setTableParamsDashboard} />
        }
      ]}
    />
    </>
    
  );
};

export default ArchiveList;
