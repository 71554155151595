import { api, apiWithoutTenant } from "../../../app/baseQueryWithReauth";
import { Clone } from "../../../types/clone";
import { Report, ReportAdd, ReportEmbed, TenantAdd } from "../../../types/report";
import { Schedule } from "../../../types/schedule";
import { Template } from "../../../types/template";
import {
  ReportUpdateOwner,
  ReportRemoveShareUser,
  ReportSharePermissionUser,
  ReportShareUser,
  SharedUser,
} from "../../../types/user";
import { toPascalCase } from "../../common/string-utils";

export const reportApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllReports: builder.query<
      ListResponse<Report>,
      {
        page: number;
        per_page: number;
        sortBy?: string;
        sortOrder?: string;
        searchString?: string
        folderId?: string;
        folderName?: string;
      }
    >({
      query: ({
        page = 1,
        per_page = 10,
        sortBy = null,
        sortOrder = "asc",
        searchString = "",
        folderId = null,
        folderName = ''
      }) => {
        let folderQuery = "";
        sortBy = toPascalCase(sortBy);
        if (folderId) {
          folderQuery = `&folderId=${folderId}`;
        }
        if (folderName) {
          folderQuery += `&folderName=${folderName}`;
        }

        let sortQuery = "";
        if (sortBy) {
          sortQuery = `&sortBy=${sortBy}&sortOrder=${sortOrder}`;
        }
        let searchQuery = "";
        if (searchString) {
          searchQuery = `&searchString=${searchString}`;
        }

        return `/report/getAllReports?pageNumber=${page}&pageSize=${per_page}${sortQuery}${folderQuery}${searchQuery}`;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: "Report" as const,
                id,
              })),
              { type: "Report", id: "LIST" },
            ]
          : [{ type: "Report", id: "LIST" }],
    }),
    getAllStandardReports: builder.query<
      ListResponse<Report>,
      {
        page: number;
        per_page: number;
        sortBy?: string;
        sortOrder?: string;
        folderId?: string;
        folderName?: string;
      }
    >({
      query: ({
        page = 1,
        per_page = 10,
        sortBy = null,
        sortOrder = "asc",
        folderId = null,
        folderName = ''
      }) => {
        sortBy = toPascalCase(sortBy);
        let folderQuery = "";
        if (folderId) {
          folderQuery = `&folderId=${folderId}`;
        }
        if (folderName) {
          folderQuery += `&folderName=${folderName}`;
        }

        let sortQuery = "";
        if (sortBy) {
          sortQuery = `&sortBy=${sortBy}&sortOrder=${sortOrder}`;
        }

        return `/report/getAllStandardReports?pageNumber=${page}&pageSize=${per_page}${sortQuery}${folderQuery}`;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: "Report" as const,
                id,
              })),
              { type: "Report", id: "LIST" },
            ]
          : [{ type: "Report", id: "LIST" }],
    }),
    getRecentReports: builder.query<
      ListResponse<Report>,
      { page: number; per_page: number; sortBy?: string; sortOrder?: string; searchString?: string }
    >({
      query: ({
        page = 1,
        per_page = 10,
        sortBy = null,
        sortOrder = "asc",
        searchString = "",
      }) => {
        sortBy = toPascalCase(sortBy);
        let sortQuery = "";
        if (sortBy) {
          sortQuery = `&sortBy=${sortBy}&sortOrder=${sortOrder}`;
        }
        let searchQuery = "";
        if (searchString) {
          searchQuery = `&searchString=${searchString}`;
        }

        return `/report/getRecentReports?pageNumber=${page}&pageSize=${per_page}${sortQuery}${searchQuery}`;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: "Report" as const,
                id,
              })),
              { type: "Report", id: "LIST" },
            ]
          : [{ type: "Report", id: "LIST" }],
    }),
    

    getBookmarkReports: builder.query<
      ListResponse<Report>,
      { page: number; per_page: number; sortBy?: string; sortOrder?: string }
    >({
      query: ({
        page = 1,
        per_page = 10,
        sortBy = null,
        sortOrder = "asc",
              }) => {
        sortBy = toPascalCase(sortBy);
        let sortQuery = "";
        if (sortBy) {
          sortQuery = `&sortBy=${sortBy}&sortOrder=${sortOrder}`;
        }
        
        return `/Report/GetBookmarkReports?pageNumber=${page}&pageSize=${per_page}${sortQuery}`;
      },
      providesTags: (result) => ["Report", "Bookmark"],
      }),

    getArchivedReports: builder.query<
      ListResponse<Report>,
      { page: number; per_page: number; }
    >({
      query: ({
        page = 1,
        per_page = 10,
              }) => {
        return `/report/GetArchivedReports?pageNumber=${page}&pageSize=${per_page}`;
      },
      providesTags: (result) => ["Report", "Archived"],
      }),




    getReportsCreatedByMe: builder.query<
      ListResponse<Report>,
      { page: number; per_page: number; sortBy?: string; sortOrder?: string; searchString?: string }
    >({
      query: ({
        page = 1,
        per_page = 10,
        sortBy = null,
        sortOrder = "asc",
        searchString = "",
      }) => {
        sortBy = toPascalCase(sortBy);
        let sortQuery = "";
        if (sortBy) {
          sortQuery = `&sortBy=${sortBy}&sortOrder=${sortOrder}`;
        }
        let searchQuery = "";
        if (searchString) {
          searchQuery = `&searchString=${searchString}`;
        }

        return `/report/getReportsCreatedByMe?pageNumber=${page}&pageSize=${per_page}${sortQuery}${searchQuery}`;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: "Report" as const,
                id,
              })),
              { type: "Report", id: "LIST" },
            ]
          : [{ type: "Report", id: "LIST" }],
    }),
    getPrivateReports: builder.query<
      ListResponse<Report>,
      { page: number; per_page: number; sortBy?: string; sortOrder?: string; searchString?: string }
    >({
      query: ({
        page = 1,
        per_page = 10,
        sortBy = null,
        sortOrder = "asc",
        searchString = "",
      }) => {
        sortBy = toPascalCase(sortBy);
        let sortQuery = "";
        if (sortBy) {
          sortQuery = `&sortBy=${sortBy}&sortOrder=${sortOrder}`;
        }
        let searchQuery = "";
        if (searchString) {
          searchQuery = `&searchString=${searchString}`;
        }

        return `/report/getPrivateReports?pageNumber=${page}&pageSize=${per_page}${sortQuery}${searchQuery}`;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: "Report" as const,
                id,
              })),
              { type: "Report", id: "LIST" },
            ]
          : [{ type: "Report", id: "LIST" }],
    }),
    getPublicReports: builder.query<
      ListResponse<Report>,
      { page: number; per_page: number; sortBy?: string; sortOrder?: string; searchString?: string  }
    >({
      query: ({
        page = 1,
        per_page = 10,
        sortBy = null,
        sortOrder = "asc",
        searchString = "",
      }) => {
        sortBy = toPascalCase(sortBy);
        let sortQuery = "";
        if (sortBy) {
          sortQuery = `&sortBy=${sortBy}&sortOrder=${sortOrder}`;
        }
        let searchQuery = "";
        if (searchString) {
          searchQuery = `&searchString=${searchString}`;
        }

        return `/report/getPublicReports?pageNumber=${page}&pageSize=${per_page}${sortQuery}${searchQuery}`;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: "Report" as const,
                id,
              })),
              { type: "Report", id: "LIST" },
            ]
          : [{ type: "Report", id: "LIST" }],
    }),
    getSharedReports: builder.query<
      ListResponse<Report>,
      { page: number; per_page: number; sortBy?: string; sortOrder?: string }
    >({
      query: ({
        page = 1,
        per_page = 10,
        sortBy = null,
        sortOrder = "asc",
      }) => {
        sortBy = toPascalCase(sortBy);
        let sortQuery = "";
        if (sortBy) {
          sortQuery = `&sortBy=${sortBy}&sortOrder=${sortOrder}`;
        }

        return `/report/getSharedReports?pageNumber=${page}&pageSize=${per_page}${sortQuery}`;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: "Report" as const,
                id,
              })),
              { type: "Report", id: "LIST" },
            ]
          : [{ type: "Report", id: "LIST" }],
    }),
    getTemplates: builder.query<Template[], void>({
      query: () => "/report/getTemplates",
      providesTags: ["Report"],
    }),
    getReport: builder.query<ReportAdd, number>({
      query: (reportId) => `/report/${reportId}`,
      transformResponse: (response: Report) => {
        return {
          id: response.id,
          name: response.name,
          description: response.description,
          templateId: response.templateId,
          folderId: response.folderId,
          private: response.private,
          published: response.published,
        };
      },
      providesTags: (result, error, id) => [{ type: "Report", id }],
    }),
    getReportEmbed: builder.query<ReportEmbed, number>({
      query: (reportId) => `/Report/GetReportEmbed?ReportId=${reportId}`,
      providesTags: (result, error, id) => [{ type: "Report", id }],
    }),
    getStandardReportEmbed: builder.query<ReportEmbed, number>({
      query: (reportId) => `/Report/GetStandardReportEmbed?ReportId=${reportId}`,
      providesTags: (result, error, id) => [{ type: "Report", id }],
    }),
    getGlossaryReport: builder.query<ReportEmbed, number>({
      query: () => `/Report/GetGlossaryReportEmbed`,
      providesTags: ["Report"],
    }),


    createReport: builder.mutation<number, Partial<ReportAdd>>({
      query: (body) => ({
        url: "/report",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Report", "Folder"],
    }),

    unArchiveReport: builder.mutation<number, {reportId: number}>({
      query: (body) => ({
        url: "/report/UnArchiveReport",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Report", "Archived"],
    }),


    addReportBookmark: builder.mutation<number, number>({
      query: (body) => ({
        url: "/report/Bookmark",
        method: "POST",
        body: {reportId: body},
      }),
      invalidatesTags: ["Report", "Bookmark"],
    }),

    deleteReportBookmark: builder.mutation<number, number>({
      query: (body) => ({
        url: "/report/DeleteBookmark",
        method: "DELETE",
        body: {reportId: body},
      }),
      invalidatesTags: ["Report", "Bookmark"],
    }),

    updateReport: builder.mutation<number, Partial<ReportAdd>>({
      query: (patch) => ({
        url: `/report/${patch.id}`,
        method: "PUT",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [
        "Folder",
        { type: "Report", id },
      ],
    }),
    deleteReport: builder.mutation<{ success: boolean; id: number }, number>({
      query(id) {
        return {
          url: `/report/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Report", "Folder"],
    }),
    getScheduleReports: builder.query<
      ListResponse<Schedule>,
      { page: number; per_page: number; sortBy?: string; sortOrder?: string }
    >({
      query: ({
        page = 1,
        per_page = 10,
        sortBy = null,
        sortOrder = "asc",
      }) => {
        sortBy = toPascalCase(sortBy);
        let sortQuery = "";
        if (sortBy) {
          sortQuery = `&sortBy=${sortBy}&sortOrder=${sortOrder}`;
        }

        return `/report/getAllReports?pageNumber=${page}&pageSize=${per_page}${sortQuery}`;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: "ScheduleReport" as const,
                id,
              })),
              { type: "ScheduleReport", id: "LIST" },
            ]
          : [{ type: "ScheduleReport", id: "LIST" }],
    }),
    reportSharedWith: builder.query<SharedUser[], number>({
      query: (reportId) => `/report/sharedWith?reportId=${reportId}`,
      providesTags: (result, error, id) =>
        result
          ? [
              ...result.map(({ userId }) => ({
                type: "SharedReportUsers" as const,
                userId,
              })),
              { type: "SharedReportUsers", id: `LIST-${id}` },
            ]
          : [{ type: "SharedReportUsers", id: `LIST-${id}` }],
    }),
    shareReport: builder.mutation<number, Partial<ReportShareUser>>({
      query: (body) => ({
        url: "/report/share",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["SharedReportUsers"],
    }),
    changeSharePermissionReport: builder.mutation<
      number,
      Partial<ReportSharePermissionUser>
    >({
      query: (body) => ({
        url: `/report/changeSharePermission`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["SharedReportUsers"],
    }),
    removeShareReport: builder.mutation<number, Partial<ReportRemoveShareUser>>(
      {
        query: (body) => ({
          url: `/report/removeShare/${body.reportId}/${body.userId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["SharedReportUsers"],
      }
    ),
    cloneReport: builder.mutation<number, Partial<Clone>>({
      query: (body) => ({
        url: "/report/clone",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Report", "Folder"],
    }),

    cloneStandardReport: builder.mutation<number, Partial<Clone>>({
      query: (body) => ({
        url: "/Report/CloneStandardReportAsReport",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Report", "Folder"],
    }),
    
    updateReportOwner: builder.mutation<number, Partial<ReportUpdateOwner>>({
      query: (body) => ({
        url: `/report/changeOwner/${body.reportId}/${body.userId}`,
        method: "PUT",
      }),
      invalidatesTags: ["SharedReportUsers", "Report"],
    }),
  }),
});

export const {
  useGetAllReportsQuery,
  useGetAllStandardReportsQuery,
  useGetRecentReportsQuery,
  useGetReportsCreatedByMeQuery,
  useGetPrivateReportsQuery,
  useGetPublicReportsQuery,
  useGetSharedReportsQuery,
  useGetReportQuery,
  useGetReportEmbedQuery,
  useGetStandardReportEmbedQuery,
  useGetGlossaryReportQuery,
  useGetTemplatesQuery,
  useCreateReportMutation,
  useUpdateReportMutation,
  useUpdateReportOwnerMutation,
  useDeleteReportMutation,
  useGetScheduleReportsQuery,
  useReportSharedWithQuery,
  useShareReportMutation,
  useChangeSharePermissionReportMutation,
  useRemoveShareReportMutation,
  useCloneReportMutation,
  useCloneStandardReportMutation,
  useGetBookmarkReportsQuery,
  useAddReportBookmarkMutation,
  useDeleteReportBookmarkMutation,
  useGetArchivedReportsQuery,
  useUnArchiveReportMutation
} = reportApiSlice;

export const reportApiSliceWithoutTenant = apiWithoutTenant.injectEndpoints({
  endpoints: (builder) => ({
    getAllAdminStandardReports: builder.query<
      ListResponse<Report>,
      {
        page: number;
        per_page: number;
        sortBy?: string;
        sortOrder?: string;
        folderId?: string;
        folderName?: string;
      }
    >({
      query: ({
        page = 1,
        per_page = 10,
        sortBy = null,
        sortOrder = "asc",
        folderId = null,
        folderName = ''
      }) => {
        sortBy = toPascalCase(sortBy);
        let folderQuery = "";
        if (folderId) {
          folderQuery = `&folderId=${folderId}`;
        }
        if (folderName) {
          folderQuery += `&folderName=${folderName}`;
        }

        let sortQuery = "";
        if (sortBy) {
          sortQuery = `&sortBy=${sortBy}&sortOrder=${sortOrder}`;
        }

        return `admin/StandardReports/GetAllStandardReports?pageNumber=${page}&pageSize=${per_page}${sortQuery}${folderQuery}`;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: "Report" as const,
                id,
              })),
              { type: "Report", id: "LIST" },
            ]
          : [{ type: "Report", id: "LIST" }],
    }),

    getTenantStandardReports: builder.query<
      ListResponse<Report>,
      {
        page: number;
        per_page: number;
        sortBy?: string;
        sortOrder?: string;
        folderId?: string;
        folderName?: string;
      }
    >({
      query: ({
        page = 1,
        per_page = 10,
        sortBy = null,
        sortOrder = "asc",
        folderId = null,
        folderName = ''
      }) => {
        sortBy = toPascalCase(sortBy);
        let folderQuery = "";
        if (folderId) {
          folderQuery = `&folderId=${folderId}`;
        }
        if (folderName) {
          folderQuery += `&folderName=${folderName}`;
        }

        let sortQuery = "";
        if (sortBy) {
          sortQuery = `&sortBy=${sortBy}&sortOrder=${sortOrder}`;
        }

        return `admin/StandardReports/GetTenantStandardReports`;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: "Report" as const,
                id,
              })),
              { type: "Report", id: "LIST" },
            ]
          : [{ type: "Report", id: "LIST" }],
    }),

    createStandardReport: builder.mutation<number, Partial<TenantAdd>>({
      query: (body) => ({
        url: "admin/StandardReports/CreateStandardReport",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Report", "Folder"],
    }),

    addTenantToStandardReport: builder.mutation<number, Partial<TenantAdd>>({
      query: (body) => ({
        url: "admin/StandardReports/AddTenantToStandardReport",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Report", "Folder"],
    })
  }),
});

export const {
  useGetAllAdminStandardReportsQuery,
  useGetTenantStandardReportsQuery,
  useCreateStandardReportMutation,
  useAddTenantToStandardReportMutation
} = reportApiSliceWithoutTenant;