import {
  createDraftSafeSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { HeaderDashboardButtons } from "../../features/dashboards/layout/dashboards-config";
import { HeaderReportButtons } from "../../features/reports/layout/reports-config";
import { Link } from "../../types/layout";


export type BreadcrumbItem = {
  $type: 'BreadcrumbItem'
  title: string
  link: string
}

export interface LayoutState {
  currentItem?: Link;
  title?: string | React.ReactElement | Array<BreadcrumbItem>;
  subtitle?: string;
  addNewUrl?: string;
  showDrawer: boolean;
  showSider: boolean;
  showHeader: boolean;
  showSubHeader: boolean;
  showGoBackButton: boolean;
  buttonsToShow: HeaderReportButtons[] | HeaderDashboardButtons[];
  layoutClass: string
}

const initialState: LayoutState = {
  title: "Reports",
  showDrawer: false,
  showSider: false,
  showHeader: true,
  showSubHeader: false,
  showGoBackButton: false,
  buttonsToShow: [],
  layoutClass: ''
};

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    updateCurrentItem(state, action: PayloadAction<Link | undefined>) {
      state.currentItem = action.payload;
    },
    updateTitle(state, action: PayloadAction<string | React.ReactElement | Array<BreadcrumbItem> | undefined>) {
      state.title = action.payload;
    },
    updateSubTitle(state, action: PayloadAction<string | undefined>) {
      state.subtitle = action.payload;
    },
    updateAddNewUrl(state, action: PayloadAction<string | undefined>) {
      state.addNewUrl = action.payload;
    },
    updateShowDrawer(state, action: PayloadAction<boolean>) {
      state.showDrawer = action.payload;
    },
    updateShowSider(state, action: PayloadAction<boolean>) {
      state.showSider = action.payload;
    },
    updateShowHeader(state, action: PayloadAction<boolean>) {
      state.showHeader = action.payload;
    },
    updateShowSubHeader(state, action: PayloadAction<boolean>) {
      state.showSubHeader = action.payload;
    },
    updateShowGoBackButton(state, action: PayloadAction<boolean>) {
      state.showGoBackButton = action.payload;
    },
    updateButtonsToShow(
      state,
      action: PayloadAction<HeaderReportButtons[] | HeaderDashboardButtons[]>
    ) {
      state.buttonsToShow = action.payload;
    },
    updateSetLayoutClass(state, action: PayloadAction<string>) {
      state.layoutClass = action.payload;
    },
  },
});

const selectSelf = (state: RootState) => state.layout;
export const isButtonToShow = createDraftSafeSelector(
  [selectSelf, (state, button) => button],
  (state, button) => Object.values(state.buttonsToShow).includes(button)
);
