import { Provider } from "react-redux";
import { HistoryRouter as Router } from "redux-first-history/rr6";
import MainRoutes from "./routes/routes";
import "./App.css";
import { store, history } from "./app/store";
import { App as AppContext } from "antd";

function App() {
  return (
      <AppContext className="app" style={{height:'100vh'}}>

      <Provider store={store}>
        <Router history={history}>
          <MainRoutes basepath=""/>
        </Router>
      </Provider>
      
      </AppContext>
  );
}

export default App;