import { Button, Input, Select, Typography } from "antd";
import { FormApi } from "final-form";
import { Field, Form } from "react-final-form";
import { Clone } from "../../types/clone";
import { useGetAllFoldersQuery } from "../../features/folders/store/folder-api-slice";
import { useLocation } from "react-router-dom";

const { Text } = Typography;

type props = {
  onClone: any;
  onCancel: any;
  initialValues?: Clone;
};

const CloneForm = ({ onClone, initialValues, onCancel }: props) => {
  const required = (value: any) => (value ? undefined : "Required");
  const minLength = (length: number) => (value: string) =>
    value.length >= length
      ? undefined
      : `Must be at least ${length} characters long`;
  const composeValidators =
    (...validators: any[]) =>
      (value: any) =>
        validators.reduce(
          (error, validator) => error || validator(value),
          undefined
        );

  const onFormSubmit = (values: Clone) => {
    onClone(values);
    currentForm.restart({});
  };
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const folderId = queryParams.get('folderId')
  const { data: folders } = useGetAllFoldersQuery(undefined);

  let currentForm: FormApi<any, Partial<any>>;
  return (
    <Form
      onSubmit={onFormSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, form, submitting, pristine, values }) => {
        currentForm = form;
        return (
          <form className="cloneForm" onSubmit={handleSubmit}>
            <Field
              name="name"
              component="input"
              validate={composeValidators(required, minLength(3))}
            >
              {({ input, meta }) => (
                <div>
                  <label>
                    Name <span style={{ color: "red" }}>*</span>
                  </label>
                  <Input
                    {...input}
                    placeholder="Name"
                    status={meta.error && meta.touched ? "error" : ""}
                  />
                  {meta.error && meta.touched && (
                    <Text type="danger">* {meta.error}</Text>
                  )}
                </div>

              )}
            </Field>
            <Field name="folderId" component="select"
            validate={composeValidators(required)}
              defaultValue={folderId ? parseInt(folderId) : null}>
              {({ input, meta }) => (
                <div className="field">
                  <label>
                    Collection Name <span style={{ color: "red" }}>*</span>
                  </label>
                  <Select
                    {...input}
                    placeholder="Collection"
                    status={meta.error && meta.touched ? "error" : ""}
                    style={{ width: "100%" }}
                  >
                    {folders
                      ? folders.map((t) => (
                        <Select.Option key={t.id} value={t.id}>
                          {t.name}
                        </Select.Option>
                      ))
                      : ""}
                  </Select>
                  {meta.error && meta.touched && (
                    <Text type="danger">* {meta.error}</Text>
                  )}
                </div>
              )}
            </Field>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "1rem",
                gap: "10px",
              }}
            >
              <Button onClick={onCancel}>Cancel</Button>
              <Button
                type="primary"
                htmlType="submit"
                disabled={submitting || pristine}
              >
                Ok
              </Button>
            </div>
          </form>
        );
      }}
    ></Form>
  );
};

export default CloneForm;
