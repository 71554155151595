import {createContext} from 'react';
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { createBrowserHistory, createMemoryHistory } from "history";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { createReduxHistoryContext } from "redux-first-history";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import { api, apiWithoutTenant } from "./baseQueryWithReauth";
import { authReducer, authSlice } from "../features/auth/store/auth-slice";
import { layoutSlice } from "../layout/store/layout-slice";
import { rtkQueryErrorLogger } from "./middleware/errorLogger";
import { searchSlice } from '../layout/store/search-slice';

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createMemoryHistory(),
    savePreviousLocations: 0,
  });

const combinedReducer = combineReducers({
  router: routerReducer,
  [api.reducerPath]: api.reducer,
  [apiWithoutTenant.reducerPath]: apiWithoutTenant.reducer,
  [authSlice.name]: authReducer,
  [layoutSlice.name]: layoutSlice.reducer,
  [searchSlice.name] : searchSlice.reducer
});

export const store = configureStore({
  reducer: combinedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(routerMiddleware, api.middleware, apiWithoutTenant.middleware, rtkQueryErrorLogger),
  // preloadedState,
  // enhancers: [monitorReducersEnhancer],
});

export const history = createReduxHistory(store);

// if (process.env.NODE_ENV !== "production" && module.hot) {
// module.hot.accept("./rootReducers", () => store.replaceReducer(rootReducer));
// }

const defaultAppContext = {
  basepath: ''
}
type AppContextType<T> = [T, React.Dispatch<React.SetStateAction<T>>]


export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export const AppContext = createContext<AppContextType<typeof defaultAppContext>>([defaultAppContext, ()=>{}])